import React, { useState, useEffect } from 'react';
import Winylo from '@winylo/winylo-react-component';

import { Link, useNavigate } from 'react-router-dom';
import { isMobile, logger, parseHash } from '../../utils/utils';

import style from './Login.module.css';
import api from '../../utils/api';

import { JWT_PERIOD } from '../../utils/constantes';
import { UserContextType, useUser } from '../../utils/context/User';
import { HasCheckLoginContextType, useHasCheckLogin } from '../../utils/context/HasCheckLogin';
import Utils from '../../components/Utils';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Login(): React.ReactElement {
  const navigate = useNavigate();
  const [user, setUser] = useUser() as UserContextType;
  const [, setHasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let hash = parseHash();

    setRedirectUrl(hash.get('redirect') === null ? '/apps' : decodeURIComponent(hash.get('redirect') as string));
  }, []);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);

    api.loginApi
      .startConnection({ login, password })
      .then((data) => {
        localStorage.setItem('jwt', data.jwt);

        setUser(data.user);
        setHasCheckLogin(true);

        setIsLoading(false);

        navigate(redirectUrl);

        setInterval(refetchMe, JWT_PERIOD);
      })
      .catch((err) => {
        logger(err);
        setErrorMessage(err.message);
        setPassword('');
        setIsLoading(false);
      });
  }

  function refetchMe() {
    api.loginApi
      .getMe()
      .then((data) => {
        logger('%cAcualisation du JWT', 'color: blue');

        localStorage.setItem('jwt', data.jwt);

        setUser(data.user);

        setInterval(refetchMe, JWT_PERIOD);
      })
      .catch((err) => {
        logger(err);
        setUser(undefined);
      });
  }

  if (user !== undefined) {
    navigate('/apps');
    return <></>;
  }

  return (
    <Utils.Container>
      <h1 className={style.title}>Veuillez vous connecter</h1>

      <Utils.Card style={{ marginTop: 0, marginBottom: 0, maxWidth: '450px', width: '100%' }}>
        <img className={style.logoEntreprise} src={process.env.PUBLIC_URL + '/logo_entreprise.png'} alt="Logo entreprise" />
        <form onSubmit={onSubmit}>
          <Winylo.TextInput
            placeholder="Identifiant"
            icon={<FontAwesomeIcon icon={faUser} />}
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            error={errorMessage !== ''}
          />

          <Winylo.TextInput
            style={{ marginTop: '0.5rem' }}
            placeholder="Mot de passe"
            icon={<FontAwesomeIcon icon={faLock} />}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errorMessage !== ''}
            type={'password'}
          />

          <div className={style.twoColumns}>
            <Winylo.Button disabled={!login || !password} loading={isLoading} type="submit">
              Connexion
            </Winylo.Button>
            <Link className={style.link} to="/forget-password">
              Mot de passe oublié ?
            </Link>
          </div>
        </form>

        {redirectUrl.startsWith('/appLogin') || isMobile() ? null : (
          <>
            <Utils.Divider variant="secondary" />

            <Winylo.Button style={{ marginBottom: '0.5rem' }} fullWidth color="yellow" onClick={() => navigate('/register')}>
              S'inscrire
            </Winylo.Button>

            <Link to="/resend-email" className={style.link}>
              Email de confirmation non reçu?
            </Link>
          </>
        )}
      </Utils.Card>
      {errorMessage !== '' ? <Utils.ErrorMessage>{errorMessage}</Utils.ErrorMessage> : null}
    </Utils.Container>
  );
}
