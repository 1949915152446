import style from './Home.module.css';
import Utils from '../../components/Utils';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { ShepherdTourContext } from 'react-shepherd'; // étapes pour l'onboarding tour "création d'une rubrique"
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { firstConnectionSteps } from '../../onboardingtours/first-connection.steps';
import { discoverApplicationsSteps } from '../../onboardingtours/discover-applications.steps';
import { dateToString, isAdmin, MY_DOMAIN, startOnBoardingTour } from '../../utils/utils';
import { createUserSteps } from '../../onboardingtours/create-user.steps';
import { editUserSteps } from '../../onboardingtours/edit-user.steps';
import { storageManagementSteps } from '../../onboardingtours/storage-management.steps';
import { patchNotes } from '../../utils/patchNotes';
import classNames from 'classnames';
import { UserContextType, useUser } from '../../utils/context/User';
import { Patchnote, ROLE } from '../../utils/api/_type';
import { TITLES } from '../../utils/constantes';
import Winylo from '@winylo/winylo-react-component';
import PatchnoteItem from './PatchnoteItem';
import { useInfiniteQuery } from 'react-query';
import api from '../../utils/api';

interface Props {
  title: string;
  children?: ReactElement;
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
}

function HomeContainer(props: Props): ReactElement {
  return (
    <>
      <div className={classNames(style.homeContainer, props.className)} onClick={(e) => props.handleClick && props.handleClick(e)}>
        <div className={style.homeContainerTitle}>{props.title}</div>
        <div className={style.homeContainerChildren}>{props.children}</div>
      </div>
    </>
  );
}

export default function Home(): ReactElement {
  const [patchNotes, setPatchNotes] = useState<Patchnote[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [onBoardingStart, setOnBoardingStart] = useState<number>(-1);

  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState<boolean>(false);
  const [isModalNeedToCreateCategoryOpen, setIsModalNeedToCreateCategoryOpen] = useState<boolean>(false);

  const tour = useContext(ShepherdTourContext);
  const navigate = useNavigate();
  let queryParams = useQueryParams();

  const { isLoading, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery(
    ['patchNotes'],
    ({ pageParam = 1 }) => api.patchNotes.getPatchnotes({ page: pageParam }),
    {
      onSuccess: (data) => {
        setPatchNotes(data.pages.map((p) => p.items).flat());
        setTotalCount(data.pages[0].pagination.totalCount);
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pagination.current < lastPage.pagination.endPage) {
          return lastPage.pagination.current + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
    }
  );

  const ContainerPatchNotes = () => {
    function getPreviews() {
      return patchNotes.slice(0, 6).map((pn) => <PatchnoteItem patchnote={pn} short />);
    }

    return (
      <>
        <span className={style.textContent}>Découvrez les dernières nouveautés</span>
        <div className={style.itemList}>
          {patchNotes ? <>{getPreviews()}</> : <div>‎ </div>}
          <span className={style.clickableItem} style={{ display: 'flex', justifyContent: 'center' }}>
            Voir plus
          </span>
        </div>
      </>
    );
  };

  function ContainerWhyWinylo() {
    return (
      <>
        <span className={style.textContent}>
          Inari est une bibliothèque d'application destinée à toutes les entreprises qui souhaitent se digitaliser en toute simplicité.
        </span>
        <div className={style.itemList}>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Clé en main
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Simple d'utilisation
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Accessible
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Sans engagement
          </div>
          <div className={style.listItem}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Testable gratuitement
          </div>
        </div>
      </>
    );
  }

  function ContainerTutorial() {
    return (
      <>
        <span className={style.textContent}>Découvrez les tutoriels participatifs</span>
        <div className={style.itemList}>
          <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(0)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Première connexion
          </div>
          <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(1)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Découvrir, tester et souscrire à une application
          </div>
          <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(2)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Ajouter un utilisateur
          </div>
          <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(3)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Modifier un utilisateur
          </div>
          <div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(4)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Gestion du stockage
          </div>
          {/*<div className={classNames(style.listItem, style.clickableItem)} onClick={() => setOnBoardingStart(5)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Tableau étiquette virtuelle
          </div> */}
        </div>
      </>
    );
  }

  function useQueryParams() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  function checkDate(index: number, array: Patchnote[], patchnote: Patchnote) {
    return index === 0 || dateToString(patchnote.scheduledAt) !== dateToString(array[index - 1]?.scheduledAt);
  }

  useEffect(() => {
    if (onBoardingStart !== -1) {
      switch (onBoardingStart) {
        case 0:
          startOnBoardingTour(tour, firstConnectionSteps(navigate));
          break;
        case 1:
          startOnBoardingTour(tour, discoverApplicationsSteps(navigate));
          break;
        case 2:
          startOnBoardingTour(tour, createUserSteps(navigate));
          break;
        case 3:
          startOnBoardingTour(tour, editUserSteps(navigate));
          break;
        case 4:
          startOnBoardingTour(tour, storageManagementSteps(navigate));
          break;
        default:
          console.log(`%conBoardinStart : bad index (${onBoardingStart})`, 'color: red');
          break;
      }
      setOnBoardingStart(-1);
    }
  }, [onBoardingStart]);

  useEffect(() => {
    document.title = TITLES.HOME;

    let startIndex = qs.parse(window.location.search, { ignoreQueryPrefix: true }).onBoardingStart;
    if (startIndex && !isNaN(startIndex as any)) {
      setOnBoardingStart(parseInt(startIndex as string));
    }
  }, []);

  useEffect(() => {
    let startIndex = queryParams.get('onBoardingStart');
    if (startIndex && !isNaN(startIndex as any)) {
      setOnBoardingStart(parseInt(startIndex as string));
    }
  }, [queryParams]);

  const [user] = useUser() as UserContextType;

  useEffect(() => {
    if (user !== undefined && user.company === null) {
      navigate('/company/create');
    }
  }, [user]);

  return (
    <>
      <Utils.Container verticalCenter={false}>
        <Utils.Card title="Accueil" isLoading={false} width="100%">
          <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img className={style.logoEntreprise} src={process.env.PUBLIC_URL + '/logo_entreprise.png'} alt="Logo entreprise" />
            <p className={style.logoText}>Bienvenue sur Inari !</p>
            <Winylo.Button color="yellow" fullWidth={false} size="md" onClick={() => navigate('/apps')} className={style.btnDiscoverApps}>
              Découvrir les applications
            </Winylo.Button>
          </span>
          <div className={style.container}>
            {user && isAdmin(user) && (
              <HomeContainer title="Premiers pas" className={style.containerTutorial}>
                {ContainerTutorial()}
              </HomeContainer>
            )}
            {/* <HomeContainer title="Parrainage">{ContainerSponsorship()}</HomeContainer> */}
            <HomeContainer title="Mises à jour" handleClick={() => setIsModalUpdateOpen(true)}>
              {ContainerPatchNotes()}
            </HomeContainer>
            {/* <HomeContainer title="Applications mobile">{ContainerMobileApps()}</HomeContainer> */}
            <HomeContainer title="Pourquoi Inari ?">{ContainerWhyWinylo()}</HomeContainer>
            {/* <HomeContainer title="Vidéo explicative">{ContainerVideo()}</HomeContainer> */}
            {!user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN) && <div style={{ flexBasis: '32%' }}></div>}
          </div>
        </Utils.Card>
      </Utils.Container>

      <Utils.Modal isOpen={isModalUpdateOpen} onClose={() => setIsModalUpdateOpen(false)} closable title={'Mises à jour'} width="50rem">
        <div style={{ maxHeight: '50vh', maxWidth: '50rem', overflowY: 'scroll' }}>
          {patchNotes.map((patchNote, key, array) => (
            <React.Fragment key={key}>
              <div key={key} style={{ display: 'flex', flexDirection: 'column' }}>
                {checkDate(key, array, patchNote) && (
                  <span style={{ fontWeight: 800, marginBottom: '0.5rem' }}>Mise à jour du {dateToString(patchNote.scheduledAt)}</span>
                )}
                <PatchnoteItem patchnote={patchNote} />
                <Utils.Divider variant="gray" />
              </div>
            </React.Fragment>
          ))}
        </div>
      </Utils.Modal>
    </>
  );
}
