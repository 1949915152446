import Utils from '../../Utils';
import style from '../MoreInfoModal.module.css';

export default function app(openOtherApp: (denomination: string) => void) {
  return {
    name: 'Inventaire',
    fileUri: 'https://inari.c-koya.tech/img/apps/big_inventaire.jpg?v1.0.0',
    description: (
      <>
        <p className={style.title}>Vos inventaires deviennent simples et rapides !</p>
        <p>
          Gagnez un temps précieux sur vos inventaires grâce à notre application mobile et fiabiliser vos stocks en limitant les erreurs de saisies.
        </p>

        <div className={style.importantContainer}>
          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/cost.svg?v1.0.0" alt="Cost" />
              <p>Réduisez le coût de vos inventaires</p>
            </div>
            <p>En optimisant la durée de vos inventaires.</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/update.svg?v1.0.0" alt="Update" />
              <p>Fiabilisez vos inventaires</p>
            </div>
            <p>Notre application empêche les erreurs de saisies.</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/accessibility.svg?v1.0.0" alt="Update" />
              <p>Facilitez le travail de vos collaborateurs</p>
            </div>
            <p>En leur mettant à disposition un outil simple et pratique.</p>
          </div>
        </div>

        <p className={style.bold}>
          L’inventaire est un moment fort d’une entreprise, il exige une masse de travail conséquente sur un temps réduit. Il peut même, dans certains
          cas, nécessiter la fermeture du site ou le recrutement de salariés externes.
        </p>

        <p>
          Inventaire fera gagner du temps à votre entreprise lors de cette activité fastidieuse avec en plus un gain considérable de fiabilité. Moins
          de temps, moins d’erreurs, un coût optimisé.
        </p>

        <p className={style.title}>Seulement en 3 étapes</p>
        <div className={style.stepsContainer}>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>1</p>
            </p>
            <p>Scannez le produit</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>2</p>
            </p>
            <p>Renseignez la quantité</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>3</p>
            </p>
            <p>Validez</p>
          </div>
        </div>
      </>
    ),
    associate: (
      <>
        <p className={style.title}>Les Win's associées à Photos & Vidéos</p>
        <div className={style.associateWin}>
          <div className={style.associateLeft}>
            <img src="https://inari.c-koya.tech/img/apps/big_media.jpg?v1.0.0" alt="Logo Capture" />
          </div>

          <div className={style.associateRight}>
            <p className={style.associateRightTitle}>Photos & Vidéos</p>
            <p>Vous pouvez connecter Inventaire à Photos & Vidéos pour créer des miniatures de produits à compter.</p>

            <p>
              <Utils.LinkButton onClick={() => openOtherApp('capture')}>Plus d'infos</Utils.LinkButton>
            </p>
          </div>
        </div>
      </>
    ),
    compatibility: (
      <>
        <p className={style.title}>Application compatible avec tous les appareils</p>

        <div className={style.compatibilityContainer}>
          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Ordinateur</p>
              <p>Création des catégories et consultation</p>
            </div>
            <div className={style.compatBottom}>
              <img src={process.env.PUBLIC_URL + '/img/ordinateur.png'} alt="Ordinateur" />
            </div>
          </div>

          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Smartphone et Tablette</p>
              <p>Scan et saisies</p>
            </div>
            <div className={style.compatBottomMultiple}>
              <div className={style.compatBottomRow}>
                <img src={process.env.PUBLIC_URL + '/img/mobile.png'} alt="Smartphone" />
                <img src={process.env.PUBLIC_URL + '/img/tablette.png'} alt="Tablette" />
              </div>

              <div className={style.compatBottomRow}>
                <img
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
                  alt="Play Store"
                  className={style.playStoreLogo}
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.winylo.inventaire&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                    )
                  }
                />
                <img
                  src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/fr-fr.svg"
                  alt="App Store"
                  className={style.appStoreLogo}
                  onClick={() => window.open('https://apps.apple.com/fr/app/inventaire-winylo/id1589914502')}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };
}
