import Utils from '../../Utils';
import style from '../MoreInfoModal.module.css';

export default function app(openOtherApp: (denomination: string) => void) {
  return {
    name: 'Photos & Vidéos',
    fileUri: 'https://api.inari.c-koya.tech//upload/application/6188cbffdd311385592935.png',
    description: (
      <>
        <p className={style.title}>Simplifiez la création et le stockage de photos et vidéos</p>
        <p>
          Photos & Vidéos est la solution qui vous permet d'optimiser la gestion de vos photos et vidéos au quotidien. Ses possibilités
          d'interconnexion vous permettent de nombreuses utilisations.
        </p>

        <div className={style.importantContainer}>
          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/gain_time.svg?v1.0.0" alt="Gain time" />
              <p>Gain important de temps</p>
            </div>
            <p>Grâce au rangement automatique de vos photos/vidéos le stockage des médias se fait en un instant.</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/easy.svg?v1.0.0" alt="Easy" />
              <p>Confort de travail</p>
            </div>
            <p>Grâce à la centralisation des médias, retrouvez facilement vos photos/vidéos et celles prises par vos collaborateurs.</p>
          </div>
        </div>

        <p className={style.bold}>
          Le stockage de photos et de vidéos peut devenir un vrai casse-tête lorsque vos collaborateurs en crées régulièrement : pour les réglages de
          machines, des pièces produites ou des réalisations sur chantier.
        </p>

        <p>
          Que cela soit avec leur smartphone ou avec un appareil photo appartenant à votre entreprise, le stockage de ces données n’est pas immédiat.
          De plus, c’est du temps perdu par le collaborateur pour exporter et sauvegarder la photo/la vidéo.
        </p>

        <p className={style.title}>Seulement en 3 étapes</p>
        <div className={style.stepsContainer}>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>1</p>
            </p>
            <p>Selectionner le dossier</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>2</p>
            </p>
            <p>Capturer une photo/vidéo</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>3</p>
            </p>
            <p>Validez</p>
          </div>
        </div>
      </>
    ),
    associate: (
      <>
        <p className={style.title}>Les Win's associées à Photos & Vidéos</p>
        <div className={style.associateWin}>
          <div className={style.associateLeft}>
            <img src="https://api.inari.c-koya.tech//upload/application/6188cc2cd1462461915100.png" alt="Logo Catalogue" />
          </div>

          <div className={style.associateRight}>
            <p className={style.associateRightTitle}>Brochure digitale</p>
            <p>
              Vous pouvez connecter Photos & Vidéos à Brochure digitale pour retrouver les photos et vidéos directement dans la galerie à la création
              des produits
            </p>
            <p>
              <Utils.LinkButton onClick={() => openOtherApp('catalogue')}>Plus d'infos</Utils.LinkButton>
            </p>
          </div>
        </div>

        <div className={style.associateWin}>
          <div className={style.associateLeft}>
            <img src="https://api.inari.c-koya.tech//upload/application/6188cc391a7af833257192.png" alt="Logo Inventaire" />
          </div>

          <div className={style.associateRight}>
            <p className={style.associateRightTitle}>Inventaire</p>
            <p>Vous pouvez connecter Photos & Vidéos à Inventaire pour créer des miniatures de produits à compter</p>
            <p>
              <Utils.LinkButton onClick={() => openOtherApp('ckomptage')}>Plus d'infos</Utils.LinkButton>
            </p>
          </div>
        </div>
      </>
    ),
    compatibility: (
      <>
        <p className={style.title}>Application compatible avec tous les appareils</p>

        <div className={style.compatibilityContainer}>
          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Ordinateur</p>
              <p>Gestion sur ordinateur</p>
            </div>
            <div className={style.compatBottom}>
              <img src={process.env.PUBLIC_URL + '/img/ordinateur.png'} alt="Ordinateur" />
            </div>
          </div>

          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Smartphone et Tablette</p>
              <p>Capture des images via l'application</p>
            </div>
            <div className={style.compatBottomMultiple}>
              <div className={style.compatBottomRow}>
                <img src={process.env.PUBLIC_URL + '/img/mobile.png'} alt="Smartphone" />
                <img src={process.env.PUBLIC_URL + '/img/tablette.png'} alt="Tablette" />
              </div>

              <div className={style.compatBottomRow}>
                <img
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png"
                  alt="Play Store"
                  className={style.playStoreLogo}
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.winylo.capture&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                    )
                  }
                />
                <img
                  src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/fr-fr.svg"
                  alt="App Store"
                  className={style.appStoreLogo}
                  onClick={() => window.open('https://apps.apple.com/fr/app/capture-winylo/id1589919257')}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };
}
