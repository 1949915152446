import { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/api';

import { getOnlyApplication, useFormState } from '../../../utils/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateUserBody, UpdateUserBody } from '../../../utils/api/users';

import * as Yup from 'yup';
import { CreateUserInterface, User } from '../../../utils/api/_type';
import CreateUserForm from '../../../components/CreateUserForm/CreateUserForm';
import Utils from '../../../components/Utils';

interface UserAdminInterface {
  username: string;
  email: string;
  plainPassword: string;
  repeatPassword: string;
  firstname: string;
  lastname: string;
  company: string;
  canConnect: boolean;
  roles: string[];
}

const AvailableRole = ['ROLE_ADMIN', 'ROLE_COMPANY_ADMIN', 'ROLE_INVOICING'];

export default function CreateUser() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string | undefined }>();

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  useEffect(() => {
    setFormState();
    setIsUsernameUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Récupération des données de l'utilisateur si en mode édition
  const { data: user, isLoading: isLoadingUser } = useQuery(
    ['user', parseInt(id === undefined ? '-1' : id)],
    () => api.users.getOneUser(parseInt(id === undefined ? '-1' : id)),
    {
      enabled: id !== undefined,
      onSuccess: (data) => {
        setFormState({
          username: data.username,
          email: data.email,
          plainPassword: '',
          repeatPassword: '',
          firstname: data.firstname,
          lastname: data.lastname,
          company: data.company ? data.company.id + '' : '-1',
          canConnect: data.canConnect,
          roles: data.roles,
        });
        setIsUsernameUpdated(true);
      },
    }
  );

  // Récupération des données des entreprises
  const { data: companies, isLoading: isLoadingCompanies } = useQuery('companies', api.companies.getAllCompanies);

  const [isUsernameUpdated, setIsUsernameUpdated] = useState(false);
  const [formField, handleInput, setFormState] = useFormState<UserAdminInterface>({
    username: '',
    email: '',
    plainPassword: '',
    repeatPassword: '',
    firstname: '',
    lastname: '',
    company: '-1',
    canConnect: false,
    roles: [],
  });

  const { mutate: createUser } = useMutation(api.users.createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      navigate('/a/user');
    },
    onMutate: () => {
      setIsSaveLoading(true);
    },
    onSettled: () => {
      setIsSaveLoading(false);
    },
  });

  // TODO: Check pourquoi la requête ne passe pas
  function handleCreateUser(createdUser: CreateUserInterface) {
    setIsSaveLoading(true);

    let body: CreateUserBody = {
      firstname: createdUser.firstname,
      lastname: createdUser.lastname,
      email: createdUser.email,
      company: parseInt(formField.company),
      username: createdUser.username,
      plainPassword: createdUser.plainPassword,
      roles: formField.roles,
    };

    if (body.plainPassword === '') delete body.plainPassword;
    if (!isUsernameUpdated) delete body.username;
    if (body.company === -1) delete body.company;

    createUser(body);
  }

  // Modification d'un utilisateur
  const { mutate: updateUser } = useMutation(api.users.updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      navigate('/a/user');
    },
    onMutate: () => {
      setIsSaveLoading(true);
    },
    onSettled: () => {
      setIsSaveLoading(false);
    },
  });

  function handleUpdate(updatedUser: CreateUserInterface) {
    setIsSaveLoading(true);

    let body: UpdateUserBody = {
      username: updatedUser.username,
      email: updatedUser.email,
      firstname: updatedUser.firstname,
      lastname: updatedUser.lastname,
      canConnect: formField.canConnect,
      roles: formField.roles,
    };

    if (formField.plainPassword !== '') {
      body.plainPassword = updatedUser.plainPassword;
    }

    updateUser({
      id: parseInt(id as string),
      body,
    });
  }

  // Gestion des checkbox de roles
  function handleCheckRole(e: any, role: string) {
    if (e.target.checked) {
      setFormState((before) => ({ ...before, roles: [...before.roles, role] }));
    } else {
      setFormState((before) => ({
        ...before,
        roles: before.roles.filter((r) => r !== role),
      }));
    }
  }

  // Gestion des roles des apps
  const [rolesState, setRolesState] = useState<{
    [key: string]: { [key: string]: boolean };
  }>({});

  const { data: companyApplications, isLoading: isLoadingCompanyApplication } = useQuery(
    ['companyApplications', user?.company?.id],
    () => api.companies.specificCompanyApplication(user?.company?.id || -1),
    {
      enabled: !isLoadingUser && user !== undefined && user.company !== null,
      onSuccess: (data) => {
        let obj: {
          [key: string]: { [key: string]: boolean };
        } = {};

        getOnlyApplication(data).forEach((companyApplication) => {
          let roleObj: { [key: string]: boolean } = {};

          companyApplication.product.availableRoles.forEach((role) => {
            let thisUserApplication = (user as User).applicationRoles.filter((app) => app.application.id === companyApplication.product.id)[0];

            if (thisUserApplication === undefined) {
              roleObj[role] = false;
            } else {
              roleObj[role] = thisUserApplication.roles.includes(role);
            }
          });

          obj[companyApplication.product.id] = roleObj;
        });

        setRolesState(obj);
      },
    }
  );

  const { mutate: updateRole } = useMutation(api.users.updateUserCompanyRole);

  function handleCheckRoleApp(e: any, idApp: number, role: string) {
    setRolesState((before) => {
      let newState: { [key: string]: { [key: string]: boolean } } = {
        ...before,
        [idApp]: { ...before[idApp], [role]: e.target.checked },
      };

      updateRole({
        userId: parseInt(id || '-1'),
        applicationId: idApp,
        roles: Object.keys(newState[idApp])
          .filter((k) => newState[idApp][k])
          .map((k) => k),
      });

      return newState;
    });
  }

  function displayRoleName(role: string) {
    if (role === 'ROLE_APPLICATION_ADMIN') {
      return "Administrateur de l'application";
    } else if (role === 'ROLE_APPLICATION_ACCESS') {
      return "Accès à l'application";
    } else {
      return role;
    }
  }

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card title="Modifier un utilisateur" width="100%" isLoading={isLoadingCompanyApplication || isLoadingUser}>
        <h1 className="center">{id !== undefined ? 'Modifier' : 'Créer'} un utilisateur</h1>

        {id === undefined && isLoadingUser ? (
          <Spinner animation="border" />
        ) : (
          <>
            <CreateUserForm
              values={user}
              onSubmit={(formUser) => (id === undefined ? handleCreateUser(formUser[0]) : handleUpdate(formUser[0]))}
              emailRequired={formField.company === '-1'}
              isLoading={isSaveLoading}
            />

            {id !== undefined ? (
              <Form.Check label="Peut se connecter" checked={formField.canConnect} onChange={handleInput} name="canConnect" id="canConnect" />
            ) : (
              <Form.Group>
                <Form.Label>Entreprise</Form.Label>
                <Form.Control as="select" value={formField.company} isInvalid={!Number(formField.company)} onChange={handleInput} name="company">
                  {isLoadingCompanies || companies === undefined ? (
                    <option value="-1">Chargement...</option>
                  ) : (
                    <>
                      <option value="-1">Pas d'entreprise</option>
                      {companies.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Control>
              </Form.Group>
            )}

            {user?.company ? (
              <>
                <h2>Roles</h2>
                {AvailableRole.map((ar) => (
                  <Form.Check key={ar} checked={formField.roles.includes(ar)} onClick={(e) => handleCheckRole(e, ar)} label={ar} id={'role-' + ar} />
                ))}

                {id !== undefined ? (
                  <>
                    <h2 style={{ marginTop: '10px' }}>Modifier les rôles</h2>
                    {isLoadingCompanyApplication || companyApplications === undefined ? (
                      <Spinner animation="border" />
                    ) : (
                      <>
                        {getOnlyApplication(companyApplications).map((cA) => (
                          <div key={cA.id}>
                            <h3>{cA.product.name}</h3>
                            {cA.product.availableRoles.map((role) => (
                              <Form.Check
                                label={displayRoleName(role)}
                                checked={rolesState[cA.product.id] !== undefined ? rolesState[cA.product.id][role] : false}
                                onClick={(e) => handleCheckRoleApp(e, cA.product.id, role)}
                                id={cA.id + '-' + role}
                              />
                            ))}
                          </div>
                        ))}
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : null}
          </>
        )}
      </Utils.Card>
    </Utils.Container>
  );
}
