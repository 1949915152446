import React, { useState } from 'react';

import style from './Login.module.css';
import api from '../../utils/api';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import * as Yup from 'yup';
import { useFormState } from '../../utils/utils';
import Utils from '../../components/Utils';

export default function FormResetPassword(): React.ReactElement {
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const [isTokenNoOk, setIsTokenNoOk] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);

  const { isLoading } = useQuery(['resetToken', token], () => token && api.loginApi.checkResetToken(token), {
    onError: () => {
      setIsTokenNoOk(true);
    },
    retry: 1,
  });

  const UserSchema = Yup.object().shape({
    password: Yup.string().min(6).required(),
    passwordConfirm: Yup.mixed().test({
      name: 'passwordTest',
      message: 'No two password match',
      test: (value: string) => {
        return value === formField.password;
      },
    }),
  });

  const [formField, handleInput] = useFormState({
    password: '',
    passwordConfirm: '',
  });

  const { mutate: resetPassword } = useMutation(api.loginApi.resetPassword, {
    onSuccess: () => {
      setIsLoadingReset(false);
      navigate('/login');
    },
  });

  function onSubmit(e: any) {
    e.preventDefault();

    setIsLoadingReset(true);
    if (!token) return;
    resetPassword({ token, body: formField });
  }

  return (
    <Utils.Container>
      <h1 className={style.title}>Mot de passe oublié</h1>

      <Utils.Card>
        {isLoading ? (
          <Utils.Spinner center={true} />
        ) : isTokenNoOk ? (
          <Utils.ErrorMessage>Le code de réinitialisation de mot de passe que vous avez utilisé n'est pas valide</Utils.ErrorMessage>
        ) : (
          <>
            <img className={style.logoEntreprise} src={process.env.PUBLIC_URL + '/logo_entreprise.png'} alt="Logo entreprise" />

            <form onSubmit={onSubmit}>
              <Utils.Input
                placeholder="Mot de passe"
                hidable
                isInvalid={!UserSchema.fields.password.isValidSync(formField.password)}
                isInvalidMessage={
                  !UserSchema.fields.password.isValidSync(formField.password) ? 'Votre mot de passe doit faire au moins 6 caractères' : undefined
                }
                value={formField.password}
                onChange={handleInput}
                name="password"
              />

              <Utils.Input
                placeholder="Répétez le mot de passe"
                hidable
                isInvalid={!UserSchema.fields.passwordConfirm.isValidSync(formField.passwordConfirm)}
                isInvalidMessage={
                  !UserSchema.fields.passwordConfirm.isValidSync(formField.passwordConfirm)
                    ? 'Vos deux mots de passes ne sont pas identiques'
                    : undefined
                }
                value={formField.passwordConfirm}
                onChange={handleInput}
                name="passwordConfirm"
              />

              <Utils.Button
                variant="primary"
                isLoading={isLoadingReset}
                disabled={!UserSchema.isValidSync(formField)}
                value="Connexion"
                type="submit"
              />
            </form>
          </>
        )}
      </Utils.Card>
    </Utils.Container>
  );
}
