import { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import * as Yup from 'yup';

import style from './Remunerations.module.css';
import { dateToString, numberToPrice } from '../../utils/utils';

interface Row {
  reference: string;
  state: 'SEND';
  date: Date;
  amount: number;
}

const state = {
  SEND: 'Envoyé',
};

export default function Remunerations() {
  const rows: Row[] = [
    {
      reference: '56966SSE',
      state: 'SEND',
      date: new Date(),
      amount: 2474.12,
    },
  ];

  const [remuAsk, setRemuAsk] = useState('400,00');
  const RemunerationSchema = Yup.mixed().test((value) => {
    return value.match(/^[0-9]{1,}(,[0-9]{0,2}){0,1}$/) !== null;
  });

  return (
    <Container>
      <h1>Mes rémunérations</h1>
      <h2>Demander une rémunération</h2>
      <p>
        Pour recevoir votre paiement, envoyez votre facture sur{' '}
        <a className={style.emailLink} href="mailto:parrainage@inari.c-koya.tech">
          parrainage@inari.c-koya.tech
        </a>
        .
      </p>
      <p>Une fois la facture reçue, le paiement sera effectué dans les plus brefs délais.</p>

      <div className={style.remuContainer}>
        <p>Montant de votre réminération à recevoir (€)</p>
        <Form.Control
          style={{ width: '200px' }}
          value={remuAsk}
          onChange={(e) => setRemuAsk(e.target.value)}
          isInvalid={!RemunerationSchema.isValidSync(remuAsk)}
          placeholder="Entrez un prix"
        />
        <p>€ HT</p>
        <Button>Demander la rémunération</Button>
      </div>

      <h2 style={{ marginTop: '10px' }}>Historique des demandes de rémunérations</h2>

      <Table responsive>
        <thead>
          <tr>
            <th>Référence de la facture</th>
            <th>Etat</th>
            <th>Date de la demande</th>
            <th>Montant (HT)</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((r) => (
            <tr key={r.reference}>
              <td>{r.reference}</td>
              <td>{state[r.state]}</td>
              <td>{dateToString(r.date)}</td>
              <td>{numberToPrice(r.amount)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
