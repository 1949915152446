import classNames from 'classnames';
import Utils from '../../components/Utils';
import Winylo from '@winylo/winylo-react-component';
import { ApplicationRole, MidApplication } from '../../utils/api/_type';

import sharedStyle from './css/Shared.module.css';
import style from './css/ApplicationRoleItem.module.css';

interface UserItemProps {
  id?: string;
  name?: string;
  application: MidApplication;
  applicationRole: ApplicationRole;
  onChange: (roles: string[]) => void;
}

export default function ApplicationRoleItem(props: UserItemProps) {
  /**
   * Met à jour le rôle de l'utilisateur
   * Fonction en partie reprise de Jules Poulain, car fonctionnelle
   *
   * @param event Event du click sur le switch
   * @param role role changé, acces ou admin
   * @param appId ip de l'application concernée
   * @param applicationRole roles de l'utilisateur sur cette application
   */
  function handleCheckRole(event: React.MouseEvent<HTMLInputElement, MouseEvent>, role: string) {
    const checked = event.currentTarget.checked;

    let roles: string[] = props.applicationRole?.roles || [];

    if (checked) {
      if (!roles.includes(role)) {
        roles.push(role);
      }
    } else {
      roles = roles.filter((r) => r !== role);
    }

    props.onChange(roles);
  }

  return (
    <>
      {props.application && (
        <div className={classNames(sharedStyle.gridRow, style.gridRow)}>
          <div className={style.application}>
            <div className={style.appImage} style={{ backgroundImage: `url('${props.application.fileUri}')` }} />
            <span>{props.application.name}</span>
          </div>
          <Winylo.Switch
            checked={props.applicationRole && props.applicationRole.roles.some((role) => role === 'ROLE_APPLICATION_ACCESS')}
            onClick={(e) => {
              handleCheckRole(e, 'ROLE_APPLICATION_ACCESS');
            }}
          />
          <Winylo.Switch
            checked={props.applicationRole && props.applicationRole.roles.some((role) => role === 'ROLE_APPLICATION_ADMIN')}
            onClick={(e) => {
              handleCheckRole(e, 'ROLE_APPLICATION_ADMIN');
            }}
          />
        </div>
      )}
    </>
  );
}
