import React, { useState } from 'react';

import style from './Login.module.css';
import api from '../../utils/api';
import { useMutation } from 'react-query';
import Utils from '../../components/Utils';
import { useNavigate } from 'react-router-dom';

export default function ForgetPassword(): React.ReactElement {
  const navigate = useNavigate();

  const [login, setLogin] = useState('');
  const [resetAsked, setResetAsked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: resetPassword } = useMutation(api.loginApi.forgetPassword, {
    onSuccess: () => {
      setIsLoading(false);
      setResetAsked(true);
    },
  });

  function onSubmit(e: any) {
    e.preventDefault();
    setIsLoading(true);
    resetPassword(login);
  }

  return (
    <Utils.Container>
      <h1 className={style.title}>Mot de passe oublié</h1>

      <Utils.Card style={{ marginTop: 0, marginBottom: 0 }}>
        <img className={style.logoEntreprise} src={process.env.PUBLIC_URL + '/logo_entreprise.png'} alt="Logo entreprise" />

        <form onSubmit={onSubmit}>
          <Utils.Input placeholder="Nom d'utilisateur ou E-mail" value={login} onChange={(e) => setLogin(e.target.value)} />

          <Utils.Button variant="primary" disabled={!login} isLoading={isLoading} type="submit">
            Réinitialiser le mot de passe
          </Utils.Button>
        </form>

        <Utils.Divider variant="secondary" />

        <Utils.Button variant="secondary" onClick={() => navigate('/login')}>
          Se connecter
        </Utils.Button>
      </Utils.Card>

      <Utils.ConfirmModal closable={false} isOpen={resetAsked} title="Demande envoyée avec succès!" text="Vérifiez votre boite mail." />
    </Utils.Container>
  );
}
