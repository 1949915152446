import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { PatchnoteSchema } from '../../../utils/schemas';
import { useFormState } from '../../../utils/utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/api';
import Toastify from 'toastify-js';
import { useParams } from 'react-router-dom';
import { RichTextEditor, RichTextEditorProps } from '@mantine/tiptap';
import Winylo, { WINYLO_THEME } from '@winylo/winylo-react-component';
import { MantineProvider } from '@mantine/core';
import { getAttributes, useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Link from '@tiptap/extension-link';

import style from './PatchnoteForm.module.css';
export default function PatchnoteForm() {
  const { id } = useParams<{ id: string | undefined }>();
  const queryClient = useQueryClient();

  // Récupération des données de la company si édition
  const { data: patchnote, isLoading: isLoadingCompany } = useQuery(
    ['patchnote', parseInt(id === undefined ? '-1' : id)],
    () => api.patchNotes.getPatchnote(parseInt(id === undefined ? '-1' : id)),
    {
      enabled: id !== undefined,
      onSuccess: (data) => {
        setFormField({
          title: data.title,
          content: data.content,
          type: data.type,
          // scheduledAt: data.scheduledAt,
        });
      },
    }
  );
  const { data: applications, isLoading: isLoadingApps } = useQuery('applications', api.applications.getAllApplications, {
    enabled: id !== undefined,
  });

  const [formField, handleForm, setFormField] = useFormState({
    title: '',
    content: '',
    type: '',
    // scheduledAt: new Date(),
  });

  useEffect(() => {
    setFormField();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { mutate: createPatchnote } = useMutation(api.patchNotes.createPatchnote, {
    onSuccess: (data, variable) => {
      setFormField();
      Toastify({
        text: 'Patchnote créée!',
        duration: 3000,
        close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
      }).showToast();
    },
  });

  function handleAddCompany() {
    createPatchnote({ ...formField });
  }

  const { mutate: updatePatchnote } = useMutation(api.patchNotes.updatePatchnote, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries('companies');
      Toastify({
        text: 'Patchnote créée/modifiéée!',
        duration: 3000,
        close: true,
        gravity: 'bottom', // `top` or `bottom`
        position: 'center', // `left`, `center` or `right`
        backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
      }).showToast();
    },
  });

  function handleUpdate() {
    updatePatchnote({
      id: parseInt(id === undefined ? '-1' : id),
      body: {
        title: formField.title,
        content: formField.content,
        type: formField.type,
      },
    });
  }

  const editor = useEditor({
    extensions: [StarterKit, Underline, Link, Superscript, SubScript, Highlight, TextAlign.configure({ types: ['heading', 'paragraph'] })],
    content: formField.content,
    onUpdate({ editor }) {
      setFormField({ ...formField, content: editor.getHTML() });
    },
  });

  return (
    <Container>
      <h1 className="center">{id === undefined ? 'Créer' : 'Modifier'} une patchnote</h1>

      {id !== undefined && isLoadingCompany ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Winylo.TextInput
            className={style.formElement}
            label="Titre"
            required
            name="title"
            value={formField.title}
            onChange={handleForm}
            error={!PatchnoteSchema.fields.title.isValidSync(formField.title)}
            style={{ marginTop: '1rem' }}
          />

          <label className={style.label}>
            Contenu<span className={style.error}> *</span>
          </label>

          <RichTextEditor editor={editor}>
            <RichTextEditor.Toolbar sticky stickyOffset={60}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.ClearFormatting />
                <RichTextEditor.Highlight />
                <RichTextEditor.Code />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Blockquote />
                <RichTextEditor.Hr />
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Subscript />
                <RichTextEditor.Superscript />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.Link />
                <RichTextEditor.Unlink />
              </RichTextEditor.ControlsGroup>

              <RichTextEditor.ControlsGroup>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
          </RichTextEditor>

          <Winylo.Select
            className={style.formElement}
            label="Type"
            required
            name="type"
            value={formField.type}
            onChange={(v) => setFormField({ ...formField, type: v ?? '' })}
            error={!PatchnoteSchema.fields.type.isValidSync(formField.type)}
            style={{ margin: '1rem 0' }}
            data={['new', 'update', 'corrected']}
          />

          {id === undefined || patchnote === undefined ? (
            <Button disabled={!PatchnoteSchema.isValidSync(formField)} onClick={handleAddCompany}>
              Créer
            </Button>
          ) : (
            <>
              <Button disabled={!PatchnoteSchema.isValidSync(formField)} onClick={handleUpdate}>
                Modifier
              </Button>

              {/* <h2>Applications</h2>
              {patchnote.companyProducts
                .filter((cP) => cP.type === 'application')
                .filter((ca) => ca.active)
                .map((ca) => (
                  <div className={style.line}>
                    <XCircleFill
                      size={25}
                      onClick={() =>
                        revokeApp({
                          idCompany: patchnote.id,
                          idApp: ca.product.id,
                        })
                      }
                    />
                    <p>{ca.product.name}</p>
                  </div>
                ))}
              <InputGroup>
                <Form.Control value={currentAddApp} onChange={(e) => setCurrentAddApp(e.target.value)} as="select">
                  {isLoadingApps || applications === undefined ? (
                    <option value="-1">Chargement...</option>
                  ) : (
                    <>
                      <option value="-1">Pas de sélection</option>
                      {applications
                        .filter(
                          (a) =>
                            patchnote.companyProducts.filter((cP) => cP.type === 'application').find((ca) => ca.product.id === a.id) === undefined ||
                            patchnote.companyProducts.filter((cP) => cP.type === 'application').find((ca) => ca.product.id === a.id)?.active === false
                        )
                        .map((a) => (
                          <option key={a.id} value={a.id}>
                            {a.name}
                          </option>
                        ))}
                    </>
                  )}
                </Form.Control>
                <InputGroup.Append>
                  <Button
                    disabled={currentAddApp === '-1'}
                    onClick={() =>
                      grantApp({
                        idApp: parseInt(currentAddApp),
                        idCompany: company.id,
                      })
                    }
                  >
                    Ajouter
                  </Button>
                </InputGroup.Append>
              </InputGroup> */}
            </>
          )}
        </>
      )}
    </Container>
  );
}
