import { useEffect, useState } from 'react';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';

import style from './ChartComponent.module.css';

interface Props {
  nbUser: number;
  nbUserActive: number;
  title: string;
}

export default function ChartComponent({ nbUser, nbUserActive, title }: Props) {
  const [data, setData] = useState([
    { value: nbUser - nbUserActive, name: 'Utilisateurs non actis' },
    { value: nbUserActive, name: 'Utiliateurs actifs' },
  ]);

  useEffect(() => {
    setData([
      { value: nbUser - nbUserActive, name: 'Utilisateurs non actis' },
      { value: nbUserActive, name: 'Utiliateurs actifs' },
    ]);
  }, [nbUser, nbUserActive]);

  return (
    <div>
      <p className={style.title}>{title}</p>
      <ResponsiveContainer height={250} width="100%">
        <PieChart>
          <Legend verticalAlign="top" />
          <Pie data={data} dataKey="value" nameKey="name" outerRadius={80} fill="#8884d8" label>
            <Cell fill="#fee006" />
            <Cell fill="#3668af" />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
