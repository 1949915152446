import { faStar, faStarHalf } from '@fortawesome/free-regular-svg-icons';
import { faSearch, faStar as faStarFill } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Toastify from 'toastify-js';
import InputMultiple from '../../../components/InputMultiple/InputMultiple';
import Utils from '../../../components/Utils';
import api from '../../../utils/api';
import { Company, ReferedUser } from '../../../utils/api/_type';
import { UserContextType, useUser } from '../../../utils/context/User';
import { copyStringToClipboard, dateToString, MY_DOMAIN, numberToPrice } from '../../../utils/utils';
import * as Yup from 'yup';

import style from './Referal.module.css';
import { TITLES } from '../../../utils/constantes';

const parrainageCount = 9;
const progressBarWidth = (parrainageCount * 100) / 20;
const tauxCommission = 0.2;

function RecommendationTab({ company }: { company?: Company }) {
  const [user] = useUser() as UserContextType;
  const [emails, setEmails] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>('');
  const [mailContent, setMailContent] = useState<string>('');
  const [showMail, setShowMail] = useState<boolean>(false);

  const referralLink = company ? `${MY_DOMAIN}/register/${company?.referralCode}` : '';

  const { mutate: sendInvitation, isLoading } = useMutation(
    'send_invitation',
    () => api.loginApi.sendInvitations({ emails, subject, message: mailContent }),
    {
      onSuccess: () => {
        setEmails([]);
        Toastify({
          text: 'Invitations envoyées !',
          duration: 3000,
          close: true,
          gravity: 'bottom', // `top` or `bottom`
          position: 'center', // `left`, `center` or `right`
          backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
        }).showToast();
      },
    }
  );

  function copyCode() {
    copyStringToClipboard(referralLink);

    Toastify({
      text: 'Code ajouté au presse-papier!',
      duration: 3000,
      close: true,
      gravity: 'bottom', // `top` or `bottom`
      position: 'center', // `left`, `center` or `right`
      backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
    }).showToast();
  }

  useEffect(() => {
    setSubject(`${user?.firstname} ${user?.lastname} vous conseille Inari`);
    setMailContent(`Bonjour,

Pour l'avoir utilisé chez ${user?.company?.name}, ${user?.firstname} vous recommande Inari : ${referralLink}
    
Inari milite pour rendre la digitalisation accessible à toutes les entreprises grâce à des applications accessibles et clés en main ! Ils proposent une période d'essai gratuite (sans CB et sans engagement). Découvrez leurs applications dès maintenant et démarrez votre digitalisation en douceur en suivant le lien ci-dessus.
    
Je suis disponible pour répondre aux éventuelles questions.
${user?.firstname} ${user?.lastname}
    
P.S. Cet email est envoyé via le Programme de Parrainage Inari. Votre adresse email n’a pas été enregistrée et elle ne sera donc jamais utilisée pour vous contacter ou partagée avec une application tierce.`);
  }, [company]);

  function onClickSend() {
    sendInvitation();
  }

  const validationSchema = Yup.object().shape({
    emails: Yup.array().of(Yup.string().email()).min(1),
    subject: Yup.string().min(2),
    message: Yup.string().min(2),
  });

  return (
    <>
      {/* <p className={style.title}>Tirez parti de votre fidélisation !</p>
      <p>
        Adipisicing amet enim anim magna tempor nisi aliqua aliquip in. Officia veniam nisi irure veniam consectetur velit est irure exercitation
        occaecat sit ipsum. Pariatur sint anim culpa laborum.
      </p> */}
      <p className={style.title}>🞂 Votre lien de parrainage</p>
      <p style={{ marginBottom: 0 }}>Et faites découvrir votre applications à toutes vos relations : clients, fournisseurs, amis</p>
      <div className={style.inputWithButton}>
        <Utils.Input inputContainerProps={{ className: style.test }} value={`${MY_DOMAIN}/register/${company?.referralCode}`} />
        <Utils.Button
          variant="secondary"
          format="square"
          additionalClassName={style.buttonCopyLink}
          containerClassName={style.buttonCopyLinkContainer}
          onClick={copyCode}
        >
          Copier le lien
        </Utils.Button>
      </div>
      {/* <p className={style.title}>🞂 Partagez sur les réseaux sociaux</p> */}
      <p className={style.title} style={{ marginTop: '1rem' }}>
        🞂 Invitez par email
      </p>
      <div className={style.invitationForm}>
        <div>
          <label>Adresse(s) email</label>
          <InputMultiple values={emails} setValues={setEmails} style={{ flex: 1 }} onClick={() => setShowMail(true)} />
        </div>
        {showMail && (
          <>
            <div className={style.invitationFormRow}>
              <label>Objet</label>
              <Utils.Input value={subject} onChange={(e) => setSubject(e.target.value)} />
            </div>
            <div className={style.invitationFormRow}>
              <label>Votre message</label>
              <Utils.Textarea rows={20} value={mailContent} onChange={(e) => setMailContent(e.target.value)}></Utils.Textarea>
            </div>
          </>
        )}
      </div>
      <Utils.Button
        fullWidth={false}
        containerClassName={style.btnSendContainer}
        additionalClassName={style.btnSend}
        onClick={() => onClickSend()}
        disabled={
          isLoading ||
          !validationSchema.isValidSync({
            emails,
            subject,
            message: mailContent,
          })
        }
      >
        Envoyer
      </Utils.Button>
    </>
  );
}

function CommissionTab({ referedUsers }: { referedUsers?: ReferedUser[] }) {
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <>
      <div className={style.bigNumbers}>
        <div className={style.bigNumber}>
          <div>1</div>
          <div>Total filleuls</div>
        </div>
        <div className={style.bigNumber}>
          <div>1</div>
          <div>Total filleuls abonnés</div>
        </div>
        <div className={style.bigNumber}>
          <div>0,00€ HT</div>
          <div>Total commissions</div>
        </div>
        <div className={style.bigNumber}>
          <div>0,00€ HT</div>
          <div>Commissions demandées</div>
        </div>
        <div className={style.bigNumber}>
          <div>0,00€ HT</div>
          <div>Rémunération à demander</div>
        </div>
      </div>
      <p className={style.title} style={{ marginTop: '1rem' }}>
        🞂 Liste des filleuls
      </p>

      <Utils.Input
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        inverted={true}
        icon={faSearch}
        placeholder="Rechercher"
        width="200px"
      />

      {/* <Utils.ImportantNumber word="Utilisateur" number={users?.pages[0]?.pagination.totalCount || 0} style={{ marginLeft: '20px' }} /> */}

      <Utils.Table>
        <thead>
          <th>Date d'expiration de votre commission</th>
          <th>Filleul</th>
          <th>Date de facturation</th>
          <th>Date de premier paiement</th>
          <th>Facture réglée</th>
          <th>Montant dépensé</th>
          <th>Montant commission</th>
          <th>Rémunération à demander</th>
        </thead>
        <tbody>
          <tr>
            <td>Commission versée</td>
            <td>IMPACT</td>
            <td>17/05/2021</td>
            <td>30/06/2021</td>
            <td className={classNames(style.invoiceStatut, style.blue)}>Oui</td>
            <td>1080,00€</td>
            <td>2160,00€</td>
            <td>0,00€</td>
          </tr>
          {referedUsers?.map((refered) => (
            <tr>
              <td>Commission versée</td>
              <td>{refered.company?.name || `${refered.firstname} ${refered.lastname}`}</td>
              <td>17/05/2021</td>
              <td>{dateToString(refered.company?.dateFirstInvoice || '')}</td>
              <td className={classNames(style.invoiceStatut, style.blue)}>Oui</td>
              <td>{numberToPrice(refered.company?.totalAmount || 0)}</td>
              <td>{numberToPrice((refered.company?.totalAmount || 0) * tauxCommission)}</td>
              <td>0,00€</td>
            </tr>
          ))}
        </tbody>
      </Utils.Table>
    </>
  );
}

function RemunerationTab() {
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <>
      <p className={style.title}>🞂 Demander une rémunération</p>
      <p>
        Pour recevoir votre paiement, envoyez nous votre facture sur <span className={style.contactMail}>parrainage@inari.c-koya.tech</span>.
        <br />
        Une fois la facture reçue, le paiement sera effectué dans les plus brefs délais.
      </p>
      <div className={style.remunerationAvailable}>
        <div className={style.noRemuneration}>Vous ne disposez actuellement d'aucune rémunération à demander</div>
      </div>
      <p className={style.title} style={{ marginTop: '1rem' }}>
        🞂 Historique des demandes de rémunérations
      </p>
      <Utils.Input
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        inverted={true}
        icon={faSearch}
        placeholder="Rechercher"
        width="200px"
      />

      {/* <Utils.ImportantNumber word="Utilisateur" number={users?.pages[0]?.pagination.totalCount || 0} style={{ marginLeft: '20px' }} /> */}

      <Utils.Table>
        <thead>
          <th>État</th>
          <th>Montant (HT)</th>
          <th>Date de la demande</th>
          <th>Référence de la facture</th>
        </thead>
        <tbody>
          <tr>
            <td className={classNames(style.invoiceStatut, style.blue)}>Envoyé</td>
            <td>2160,00€</td>
            <td>17/05/2021</td>
            <td>ABC-123</td>
          </tr>
        </tbody>
      </Utils.Table>
    </>
  );
}

export default function Referal() {
  const [user] = useUser() as UserContextType;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [emails, setEmails] = useState<string[]>([]);
  // Récupération des entreprises parainées
  const { data: refered, isLoading: isLoadingRefered } = useQuery('companyRefered', api.companies.getCompanyRefered);

  // Copie du code de referal
  const { data: company, isLoading } = useQuery(['company', user!.company!.id], () => api.companies.getOneCompany(user!.company!.id));

  function copyCode() {
    copyStringToClipboard(`${MY_DOMAIN}/register/${company?.referralCode}`);

    Toastify({
      text: 'Code ajouté au presse-papier!',
      duration: 3000,
      close: true,
      gravity: 'bottom', // `top` or `bottom`
      position: 'center', // `left`, `center` or `right`
      backgroundColor: 'linear-gradient(to right, #ff5f6d, #ffc371)',
    }).showToast();
  }

  // const tabs = ['Recommandez Inari', 'Vos filleuls & commissions', 'Vos rémunérations'];
  const tabs = ['Recommandez Inari']; // TEMP
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);

  useEffect(() => {
    document.title = TITLES.SPONSORSHIP;
  }, []);

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card width="100%" title="Parrainage" isLoading={isLoading || isLoadingRefered}>
        <>
          {/* 
          <div className={style.starsContainer}>
            <div className={style.starItem}>
              <FontAwesomeIcon icon={faStar} size="lg" />
              <span>0</span>
            </div>
            <div className={style.starItem}>
              <FontAwesomeIcon icon={faStarHalf} size="lg" />
              <span>10</span>
            </div>
            <div className={style.starItem}>
              <span>20</span>
              <FontAwesomeIcon icon={faStarFill} size="lg" />
            </div>
          </div>
          <div className={style.progressBar}>
            <div className={style.progressBarContent} style={{ width: `${progressBarWidth}%` }}></div>
          </div>
          <p className={style.tauxCommission}>Votre taux de commission est de {tauxCommission * 100}%</p>
  */}
          <Utils.CustomTabs tabs={tabs} selectedTab={selectedTab} onChangeSelectedTab={(tab) => setSelectedTab(tab)}>
            <>
              {selectedTab === 'Recommandez Inari' ? (
                <RecommendationTab company={company} />
              ) : selectedTab === 'Vos filleuls & commissions' ? (
                <CommissionTab referedUsers={refered} />
              ) : (
                <RemunerationTab />
              )}
            </>
          </Utils.CustomTabs>
          {/* <p>
            Vous pouvez parrainer d'autres entreprises via le lien ci dessous
          </p>
          <Utils.Button
            format="square"
            fullWidth={false}
            style={{ marginRight: 'auto' }}
            onClick={() => setIsModalOpen(true)}
          >
            Parrainer une entreprise
          </Utils.Button>

          <div className={style.companyContainer}>
            {refered?.map((userRefered) => (
              <div className={style.company}>
                {userRefered.company.uri !== null ? (
                  <img
                    src={userRefered.company.uri}
                    alt={`Logo de ${userRefered.company.uri}`}
                  />
                ) : null}
                <p>{userRefered.company.name}</p>
              </div>
            ))}
            {[...Array(4 - ((refered?.length || 0) % 4)).keys()].map((key) => (
              <div key={'void-' + key}></div>
            ))}
          </div> */}
        </>
      </Utils.Card>
      {/* <Utils.Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closable
      >
        <>
          <p>Utilisez l'adresse ci-dessous pour parrainer une entreprise</p>
          <Utils.Input
            value={`${MY_DOMAIN}/register/${company?.referralCode}`}
          />
          <Utils.Button format="square" fullWidth={false} onClick={copyCode}>
            Copier l'adresse
          </Utils.Button>
        </>
      </Utils.Modal> */}
    </Utils.Container>
  );
}
