import { MY_DOMAIN } from '../../../utils/utils';
import style from '../MoreInfoModal.module.css';

export default function app(openOtherApp: (denomination: string) => void) {
  return {
    name: 'Boite à idées digitale',
    fileUri: 'https://inari.c-koya.tech/img/apps/big_kaizen.jpg?v1.0.0',
    description: (
      <>
        <p className={style.title}>Permettez à TOUS vos collaborateurs de participer à votre amélioration continue !</p>
        <p>Optimisez votre process d’amélioration continue grâce à une application simple et à destination de tous vos collaborateurs.</p>

        <div className={style.importantContainer}>
          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/upgrade.svg?v1.0.0" alt="Upgrade" />
              <p>Faites progresser votre entreprise</p>
            </div>
            <p>Grâce aux idées de vos collaborateurs</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/love.svg?v1.0.0" alt="Love" />
              <p>Renforcez le sentiment d'appartenance</p>
            </div>
            <p>Fédérez tous vos collaborateurs même ceux qui ne sont pas sur site</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/time.svg?v1.0.0" alt="Time" />
              <p>Gagnez du temps</p>
            </div>
            <p>Grâce à une animation simplifiée du process d'amélioration continue</p>
          </div>
        </div>

        <p className={style.bold}>
          Grâce à la win’s Boite à idées digitale, impliquez l’ensemble de vos collaborateurs à l’amélioration continue, qu’ils soient dans vos locaux
          ou à distance, qu’il soit dans un atelier ou en déplacement.
        </p>

        <p>
          Vous générez des QR codes selon des thématiques, des services, des endroits dans l’entreprise, etc... Ensuite, vos collaborateurs scannent
          ces codes pour proposer une idée ou remonter une information. Vous pouvez aussi partager un lien URL qui dirigera votre collaborateur
          directement sur le même formulaire. Plus que récoltez des idées, vous aurez le temps de les traiter !
        </p>

        <p className={style.title}>Seulement en 3 étapes</p>
        <div className={style.stepsContainer}>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>1</p>
            </p>
            <p>Créez et partagez le formulaire</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>2</p>
            </p>
            <p>Consultez les idées</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>3</p>
            </p>
            <p>Traitez les idées</p>
          </div>
        </div>
      </>
    ),
    associate: undefined,
    video: (
      <div className={style.contentVideo}>
        <div className={style.ecran}>
          <img src={`${MY_DOMAIN}/img/illu-video.svg`} alt="Ecran" />
        </div>
        <div className={style.video}>
          <iframe
            width="720"
            height="405"
            src="https://www.youtube.com/embed/RMpDfCDAY78"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    ),
    compatibility: (
      <>
        <p className={style.title}>Application compatible avec tous les appareils</p>

        <div className={style.compatibilityContainer}>
          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Ordinateur</p>
              <p>Gestion et entrée d'idées</p>
            </div>
            <div className={style.compatBottom}>
              <img src={process.env.PUBLIC_URL + '/img/ordinateur.png'} alt="Ordinateur" />
            </div>
          </div>

          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Smartphone et Tablette</p>
              <p>Entrée d'idées</p>
            </div>
            <div className={style.compatBottomMultiple}>
              <div className={style.compatBottomRow}>
                <img src={process.env.PUBLIC_URL + '/img/mobile.png'} alt="Smartphone" />
                <img src={process.env.PUBLIC_URL + '/img/tablette.png'} alt="Tablette" />
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };
}
