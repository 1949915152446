import { Link } from 'react-router-dom';

import { Megaphone, Building, Wallet } from 'react-bootstrap-icons';

import style from './SidePannel.module.css';

export default function SidePannelConsultant({ children }: { children: React.ReactElement }) {
  return (
    <div className={style.widthContainer}>
      <div className={style.left}>
        <Link to="/c">
          <Megaphone /> Recommander Inari
        </Link>
        <Link to="/c/commissions">
          <Building /> Mes commissions
        </Link>
        <Link to="/c/remunerations">
          <Wallet /> Mes rémunérations
        </Link>
      </div>
      <div className={style.right}>{children}</div>
    </div>
  );
}
