export const JWT_PERIOD = 40 * 60 * 1000;

export const PLATEFORM_NAME = 'Inari - ';

export const TITLES = {
  HOME: PLATEFORM_NAME + 'Accueil',
  APPS_LIST: PLATEFORM_NAME + 'Applications',
  COMPANY_PROFILE: PLATEFORM_NAME + "Profil d'entreprise",
  SUBSCRIPTIONS_LIST: PLATEFORM_NAME + 'Mes abonnements',
  METHODS_LIST: PLATEFORM_NAME + 'Mes méthodes de paiement',
  ADDRESSES_LIST: PLATEFORM_NAME + 'Mes adresses de paiements',
  SPONSORSHIP: PLATEFORM_NAME + 'Parrainage',
  TRIALS_LIST: PLATEFORM_NAME + 'Demandes de tests',
  USERS_LIST: PLATEFORM_NAME + 'Gestion des utilisateurs',
  RIGHTS_LIST: PLATEFORM_NAME + 'Gestion des droits',
  STATISTICS: PLATEFORM_NAME + 'Statistiques',
  STORAGE: PLATEFORM_NAME + 'Stockage',

  CREATE_USERS: PLATEFORM_NAME + 'Créer des utilisateurs',
  UPDATE_USER: PLATEFORM_NAME + 'Modifier un utilisateur',
  ROLES_LIST: PLATEFORM_NAME + 'Gestion des droits',

  MY_PROFILE: PLATEFORM_NAME + 'Mon profil',
  ADMIN: PLATEFORM_NAME + 'Administration',
};

export const PRODUCT_TYPE = {
  APPLICATION: 'application',
  SCREEN_LICENCE: 'screen_licence',
  STORAGE: 'storage',
};
