import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../utils/api';
import { useEffect, useState } from 'react';
import { UserContextType, useUser } from '../../utils/context/User';
import { Company, MidApplication, ROLE } from '../../utils/api/_type';
import Utils from '../../components/Utils';

import style from './Applications.module.css';

import { compare, isFilter, numberToPrice } from '../../utils/utils';
import { BasketContextType, useBasket } from '../../utils/context/Basket';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTh, faBars, faHourglassHalf, faCartPlus, faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import MoreInfoModal from '../../components/MoreInfoModal/MoreInfoModal';
import { ReactComponent as Check } from '../../icons/check.svg';
import { useNavigate } from 'react-router-dom';
import { TITLES } from '../../utils/constantes';
import Winylo from '@winylo/winylo-react-component';
import { BasketFill } from 'react-bootstrap-icons';

export default function AllApplication() {
  const [user] = useUser() as UserContextType;
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [basket, setBasket] = useBasket() as BasketContextType;

  const [searchValue, setSearchValue] = useState('');
  const [selectedTab, setSelectedTab] = useState<'Toutes les applications' | 'Mes applications' | "En cours d'essais">('Toutes les applications');
  let tabs = ['Toutes les applications'];
  if (user) tabs.push(...['Mes applications', "En cours d'essais"]);

  const [displayMode, setDisplayMode] = useState<'grid' | 'row'>('grid');

  const [companyApplications, setCompanyApplications] = useState<number[]>([]);
  const [companyTrialApplications, setCompanyTrialApplications] = useState<number[]>([]);
  const [companyTrialExpiredApplications, setCompanyTrialExpiredApplications] = useState<number[]>([]);

  const [isOpenMoreInfoModal, setIsOpenMoreInfoModal] = useState<undefined | MidApplication>(undefined);

  const { data: company, isLoading: isLoadingCompany } = useQuery(
    ['company', user?.company?.id],
    () => api.companies.getOneCompany(user?.company?.id || -1),
    {
      enabled: user !== undefined && user.company !== null,
      onSuccess: (data) => {
        setCompanyApplications(
          data.companyProducts
            .filter((ca) => ca.active)
            .filter((ca) => ca.type === 'application')
            .map((a) => a.product.id)
        );
        setCompanyTrialApplications(
          data.companyProducts
            .filter((a) => a.active)
            .filter((a) => a.status === 'trial')
            .filter((a) => a.type === 'application')
            .map((a) => a.product.id)
        );
        setCompanyTrialExpiredApplications(
          data.companyProducts
            .filter((a) => a.status === 'trial_expired')
            .filter((a) => a.type === 'application')
            .map((a) => a.product.id)
        );
      },
    }
  );

  const { data: applications, isLoading: isLoadingApplications } = useQuery('applications', api.applications.getAllApplications);

  /**
   * Renvoit les applications en fonction du SelectedTabs
   */
  function getSelectedApplications() {
    if (applications === undefined) return [];

    let apps = applications.filter((app) => isFilter(app.name, searchValue));

    if (selectedTab === 'Toutes les applications') {
      return apps;
    } else if (selectedTab === 'Mes applications') {
      return apps.filter((app) => companyApplications.includes(app.id));
    } else if (selectedTab === "En cours d'essais") {
      return apps.filter((app) => companyTrialApplications.includes(app.id));
    } else {
      return [];
    }
  }

  /**
   * Ajoute une application au panier
   * @param app
   */
  function addAppToBasket(app: MidApplication) {
    if (!basket.map((basketApp) => basketApp.application.id).includes(app.id)) {
      setBasket((before) => [...before, { addedDate: new Date(), application: app }]);
    }
  }

  /**
   * Retire une application du panier
   * @param app
   */
  function removeAppFromBasket(app: MidApplication) {
    setBasket((before) => before.filter((basketApp) => basketApp.application.id !== app.id));
  }

  // Gestion des essais
  const { mutate: tryApplication } = useMutation(api.applications.tryApplication, {
    onSuccess: (data) => {
      queryClient.setQueryData<Company | undefined>(['company', user?.company?.id], (before) => {
        if (before === undefined) return before;

        return {
          ...before,
          companyProducts: [...before.companyProducts, data],
        };
      });

      // setCompanyTrialApplications((before) => [...before, data.product.id]);
    },
  });

  const { mutate: addTrialRequest, isLoading: isLoadingAddTrialRequest } = useMutation(api.trialrequest.addTrialRequest, {
    onSuccess: () => {
      setIsModalAskTryOpen(undefined);
      queryClient.invalidateQueries('trialRequests');
    },
  });

  const [isModalAskTryOpen, setIsModalAskTryOpen] = useState<undefined | MidApplication>(undefined);
  const [modalAskTextarea, setModalAskTextarea] = useState('');

  function handleTryApplication(app: MidApplication) {
    if (user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN)) {
      tryApplication(app.id);
    } else {
      setModalAskTextarea('');
      setIsModalAskTryOpen(app);
    }
  }

  function handleAskTryApplication() {
    if (isModalAskTryOpen === undefined || modalAskTextarea === '') return;

    addTrialRequest({
      comment: modalAskTextarea,
      application: isModalAskTryOpen.id,
    });
  }

  function parseDateEndTry(inputDate: string | null | undefined) {
    if (inputDate === undefined || inputDate === null) return '';

    let endDate = new Date(inputDate);
    let today = new Date();

    let difference = endDate.getTime() - today.getTime();
    let differenceDay = Math.trunc(difference / (1000 * 60 * 60 * 24));

    let color = '';

    if (1 <= differenceDay && differenceDay <= 4) {
      color = 'red';
    } else if (5 <= differenceDay && differenceDay <= 10) {
      color = 'yellow';
    } else {
      color = 'green';
    }

    return (
      <p className={classNames(style.tryTime, style[color])}>
        <FontAwesomeIcon icon={faHourglassHalf} />
        {differenceDay + (displayMode === 'row' ? ` jour${differenceDay > 1 ? 's' : ''} restants` : ` Jour${differenceDay > 1 ? 's' : ''}`)}
      </p>
    );
  }

  // Prise en compte de l'ouverture du petit modal des stores
  const [isModalMobileAppOpen, setIsModalMobileAppOpen] = useState<
    | undefined
    | {
        app: MidApplication;
        pos: { top: string; left?: string; right?: string };
      }
  >(undefined);
  function handleOpenModal(e: React.MouseEvent<SVGSVGElement, MouseEvent>, app: MidApplication) {
    e.stopPropagation();
    let rect = (e.target as SVGSVGElement).getBoundingClientRect();

    let pos: any = {
      top: rect.top + document.documentElement.scrollTop + 'px',
    };

    if (rect.left + 300 > window.innerWidth) {
      pos = { ...pos, right: window.innerWidth - rect.left + 'px' };
    } else {
      pos = { ...pos, left: rect.left + 10 + 'px' };
    }

    document.addEventListener('click', clickHandler);

    function clickHandler(e: MouseEvent) {
      e.stopPropagation();

      document.removeEventListener('click', clickHandler);

      setIsModalMobileAppOpen(undefined);
    }

    setIsModalMobileAppOpen({
      app,
      pos,
    });
  }

  // Récupération des trialRequests
  const { data: trialRequests } = useQuery('trialRequests', () => api.trialrequest.getTrialRequest({ page: 1 }), {
    enabled: user !== undefined,
  });

  useEffect(() => {
    if (user !== undefined && user.company === null) {
      navigate('/company/create');
    }
  }, [user]);

  useEffect(() => {
    document.title = TITLES.APPS_LIST;
  }, []);

  function TestButton(app: MidApplication, fullWidth = false) {
    if (user === undefined) {
      return (
        <>
          <Winylo.Button
            className={style.testThick}
            style={{
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: 700,
            }}
            format="square"
            onClick={() => {
              navigate('/login');
            }}
            fullWidth={false}
            size="md"
          >
            Tester l'application
          </Winylo.Button>

          <Winylo.Button
            className={style.testThin}
            style={{
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: 700,
            }}
            format="square"
            onClick={() => {
              navigate('/login');
            }}
            fullWidth={false}
            size="md"
          >
            Tester
          </Winylo.Button>
        </>
      );
    }

    if (trialRequests === undefined || trialRequests.items === undefined || companyTrialExpiredApplications.includes(app.id) || app.comingSoon)
      return;

    let trialApp = trialRequests.items.find((trial) => trial.application.id === app.id);

    if (isCompanyAdmin()) {
      return (
        <Winylo.Button
          style={{
            whiteSpace: 'nowrap',
            fontSize: '14px',
            fontWeight: 700,
          }}
          format="square"
          onClick={() => handleTryApplication(app)}
          fullWidth={false}
          size="md"
        >
          Tester l'application
        </Winylo.Button>
      );
    }

    return (
      <Winylo.Button
        style={{
          whiteSpace: 'nowrap',
          fontSize: '14px',
          fontWeight: 700,
        }}
        format="square"
        onClick={() => handleTryApplication(app)}
        disabled={trialRequests?.items?.map((trial) => trial.application.id).includes(app.id)}
        color="yellow"
        fullWidth={fullWidth}
        size="md"
      >
        {trialApp !== undefined
          ? `Demande de test ${trialApp.status === 'granted' ? 'acceptée' : trialApp.status === 'refused' ? 'refusée' : 'en attente'}`
          : "Demander à tester l'application"}
      </Winylo.Button>
    );
  }

  function OpenButton(app: MidApplication) {
    const companyProduct = company?.companyProducts.find((companyApp) => companyApp.product.id === app.id);
    return (
      <>
        <Winylo.Button
          format="square"
          fullWidth={false}
          onClick={() => window.open(app.urlWeb)}
          style={{
            whiteSpace: 'nowrap',
            fontSize: '14px',
            fontWeight: 700,
          }}
          size="md"
        >
          Ouvrir
        </Winylo.Button>

        {companyProduct && companyProduct.subscriptionEnd && <p className={style.timeLeft}>{parseDateEndTry(companyProduct.subscriptionEnd)}</p>}
      </>
    );
  }

  function isCompanyAdmin() {
    return user?.roles.includes(ROLE.ROLE_COMPANY_ADMIN);
  }

  return (
    <Utils.Container verticalCenter={false}>
      <Utils.Card title="Applications" isLoading={isLoadingApplications || isLoadingCompany} width="100%">
        <Utils.CustomTabs
          tabs={tabs}
          onChangeSelectedTab={(tab) => setSelectedTab(tab as any)}
          selectedTab={selectedTab}
          additionnalContainerClassNames={style.additionnalContainerClassNames}
          additionnalChildrenClassNames={style.additionnalChildrenClassNames}
          responsive
        >
          <div className={classNames(style.tabContainer, 'onboarding-discovercategories-2nd')}>
            <div id="wynboarding-test" className={style.tabTop}>
              <Winylo.TextInput
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                icon={<FontAwesomeIcon icon={faSearch} />}
                placeholder="Rechercher"
                width="150px"
              />

              <Utils.ImportantNumber word="Application" number={getSelectedApplications().length} style={{ marginLeft: '20px' }} />

              <div className={style.iconContainer}>
                <FontAwesomeIcon
                  icon={faTh}
                  className={classNames(style.icon, {
                    [style.selectedIcon]: displayMode === 'grid',
                  })}
                  onClick={() => setDisplayMode('grid')}
                />
                <FontAwesomeIcon
                  icon={faBars}
                  className={classNames(style.icon, {
                    [style.selectedIcon]: displayMode === 'row',
                  })}
                  onClick={() => setDisplayMode('row')}
                />
              </div>
            </div>

            {displayMode === 'row' ? (
              <Utils.Table>
                <thead>
                  <th style={{ width: '50%' }}>Nom de l'application</th>
                  {isCompanyAdmin() ? (
                    <>
                      <th>Abonnement</th>
                      <th></th>
                    </>
                  ) : null}
                  <th style={{ width: '150px' }} className={style.addToCartTd}></th>
                  <th style={{ width: '150px' }}></th>
                </thead>
                <tbody>
                  {getSelectedApplications().map((app) => (
                    <tr key={app.id}>
                      <td className={style.firstColumn}>
                        {app.fileUri !== null ? <img className={style.appLogo} src={app.fileUri} alt="Logo de l'app" /> : null}
                        <div className={style.rightFirstColumn}>
                          <p className={style.firstColumnAppTitle}>{app.name}</p>
                          <p className={style.description}>{app.description}</p>
                          <p>
                            <Utils.LinkButton onClick={() => setIsOpenMoreInfoModal(app)} className={style.listMoreInfo}>
                              Plus d'infos
                            </Utils.LinkButton>
                          </p>
                        </div>
                      </td>
                      {user === undefined && !app.comingSoon && (
                        <td>
                          <Winylo.Button
                            className={style.addToCartThick}
                            fullWidth={false}
                            format="square"
                            color="yellow"
                            onClick={() => navigate('/login')}
                            style={{
                              whiteSpace: 'nowrap',
                              fontSize: '14px',
                              fontWeight: 700,
                            }}
                            size="md"
                          >
                            Ajouter au panier
                          </Winylo.Button>

                          <div className={style.addToCartThinContainer}>
                            <FontAwesomeIcon className={style.addToCartThin} icon={faCartPlus} onClick={() => navigate('/login')} />
                          </div>
                        </td>
                      )}
                      {!user && <td className={style.price}>{app.price !== null ? numberToPrice(app.price) + ' HT / mois' : null}</td>}
                      {isCompanyAdmin() ? (
                        <>
                          <td>{app.price !== null ? numberToPrice(app.price) + ' HT / mois' : null}</td>
                          <td>
                            {companyTrialApplications.includes(app.id)
                              ? parseDateEndTry(company?.companyProducts.find((companyApp) => companyApp.product.id === app.id)?.trialEndedAt)
                              : null}
                          </td>
                          <td>
                            <>
                              {companyTrialApplications.includes(app.id) ? null : companyApplications.includes(app.id) ? (
                                <Winylo.Button
                                  color="green"
                                  fullWidth={false}
                                  format="square"
                                  className={style.btnSubscribed}
                                  style={{ width: '100%' }}
                                  size="md"
                                >
                                  <Check
                                    style={{
                                      width: '16px',
                                      height: '16px',
                                      marginRight: '4px',
                                    }}
                                  />
                                  Abonné
                                </Winylo.Button>
                              ) : null}

                              {!app.comingSoon && (companyTrialApplications.includes(app.id) || !companyApplications.includes(app.id)) ? (
                                basket.map((basketItem) => basketItem.application.id).includes(app.id) ? (
                                  <Winylo.Button fullWidth={false} format="square" color="yellow" onClick={() => removeAppFromBasket(app)} size="md">
                                    Retirer du panier
                                  </Winylo.Button>
                                ) : (
                                  <Winylo.Button
                                    className="onboarding-discovercategories-3rd"
                                    fullWidth={false}
                                    format="square"
                                    color="yellow"
                                    onClick={() => addAppToBasket(app)}
                                    style={{
                                      whiteSpace: 'nowrap',
                                      fontSize: '14px',
                                      fontWeight: 700,
                                    }}
                                    size="md"
                                  >
                                    Ajouter au panier
                                  </Winylo.Button>
                                )
                              ) : null}
                            </>
                          </td>
                        </>
                      ) : (
                        <td>
                          {' '}
                          {companyTrialApplications.includes(app.id)
                            ? parseDateEndTry(company?.companyProducts.find((companyApp) => companyApp.product.id === app.id)?.trialEndedAt)
                            : null}
                        </td>
                      )}
                      <td>
                        {companyApplications.includes(app.id) ? (
                          <Winylo.Button
                            format="square"
                            onClick={() => window.open(app.urlWeb)}
                            style={{
                              whiteSpace: 'nowrap',
                              fontSize: '14px',
                              fontWeight: 700,
                              width: '100%',
                            }}
                            size="md"
                          >
                            Ouvrir
                          </Winylo.Button>
                        ) : app.trialDuration !== null ? (
                          TestButton(app, true)
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Utils.Table>
            ) : (
              <div className={style.gridContainer}>
                {getSelectedApplications().map((app) => (
                  <div className={style.gridItem}>
                    <div className={style.leftGridPart}>{app.fileUri !== null ? <img src={app.fileUri} alt="Logo de l'app" /> : null}</div>
                    <div className={style.rightGridPart}>
                      <div className={style.rightGridTop}>
                        <p className={style.gridTitle}>
                          {app.name} {app.isBeta && <span className={style.isBeta}>VERSION BETA</span>}
                        </p>
                        {isCompanyAdmin() && <p className={style.gridPrice}>{app.price !== null ? numberToPrice(app.price) + ' HT / mois' : null}</p>}
                        {app.urlIos !== null ||
                          (app.urlAndroid !== null && (
                            <FontAwesomeIcon icon={faEllipsisV} className={style.dots} onClick={(e) => handleOpenModal(e, app)} />
                          ))}
                      </div>

                      <p className={style.gridDesc}>{app.description}</p>

                      <Utils.LinkButton onClick={() => setIsOpenMoreInfoModal(app)} className={style.gridMoreInfo}>
                        Plus d'infos
                      </Utils.LinkButton>

                      <div className={style.gridButtonContainer}>
                        {user === undefined && !app.comingSoon && (
                          <>
                            <Winylo.Button
                              className={style.addToCartThickGrid}
                              format="square"
                              color="yellow"
                              fullWidth={false}
                              onClick={() => navigate('/login')}
                              style={{
                                whiteSpace: 'nowrap',
                                fontSize: '14px',
                                fontWeight: 700,
                              }}
                              size="md"
                            >
                              Ajouter au panier
                            </Winylo.Button>

                            <div className={style.addToCartThinGridContainer}>
                              <FontAwesomeIcon className={style.addToCartThin} icon={faCartPlus} onClick={() => navigate('/login')} />
                            </div>
                          </>
                        )}
                        {isCompanyAdmin() && companyApplications.includes(app.id) && !companyTrialApplications.includes(app.id) ? (
                          <Winylo.Button format="square" color="green" fullWidth={false} size="md">
                            <Check
                              style={{
                                width: '16px',
                                height: '16px',
                                marginRight: '4px',
                              }}
                            />
                            Abonné
                          </Winylo.Button>
                        ) : null}
                        {companyApplications.includes(app.id) ? OpenButton(app) : app.trialDuration !== null ? TestButton(app) : null}
                        {isCompanyAdmin() ? (
                          !app.comingSoon && (companyTrialApplications.includes(app.id) || !companyApplications.includes(app.id)) ? (
                            basket.map((basketItem) => basketItem.application.id).includes(app.id) ? (
                              <Winylo.Button fullWidth={false} format="square" color="yellow" onClick={() => removeAppFromBasket(app)} size="md">
                                Retirer du panier
                              </Winylo.Button>
                            ) : (
                              <Winylo.Button
                                className="onboarding-discovercategories-3rd"
                                fullWidth={false}
                                format="square"
                                color="yellow"
                                onClick={() => addAppToBasket(app)}
                                style={{
                                  whiteSpace: 'nowrap',
                                  fontSize: '14px',
                                  fontWeight: 700,
                                }}
                                size="md"
                              >
                                Ajouter au panier
                              </Winylo.Button>
                            )
                          ) : null
                        ) : null}
                        {companyTrialApplications.includes(app.id) ? (
                          <p className={style.timeLeft}>
                            {parseDateEndTry(company?.companyProducts.find((companyApp) => companyApp.product.id === app.id)?.trialEndedAt)}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
                {getSelectedApplications().length % 2 !== 0 ? <div className={style.gridSpace} /> : null}
              </div>
            )}
          </div>
        </Utils.CustomTabs>
      </Utils.Card>

      {isModalMobileAppOpen !== undefined ? (
        <div className={style.modalMobileApp} style={isModalMobileAppOpen.pos}>
          {isModalMobileAppOpen.app.urlAndroid !== null && (
            <div
              className={classNames(style.modalMobileAppRow, style.enabledLink)}
              onClick={isModalMobileAppOpen.app.urlAndroid !== null ? () => window.open(isModalMobileAppOpen.app.urlAndroid as string) : undefined}
            >
              <img src={process.env.PUBLIC_URL + '/store/googleplay.svg'} alt="Logo Google Play" />
              <p>Télécharger sur le Play Store</p>
            </div>
          )}
          {isModalMobileAppOpen.app.urlIos !== null && (
            <div
              className={classNames(style.modalMobileAppRow, style.enabledLink)}
              onClick={isModalMobileAppOpen.app.urlIos !== null ? () => window.open(isModalMobileAppOpen.app.urlIos as string) : undefined}
            >
              <img src={process.env.PUBLIC_URL + '/store/appstore.png'} alt="Logo App Store" />
              <p>Télécharger sur l'App Store</p>
            </div>
          )}
        </div>
      ) : null}

      <Utils.Modal
        isOpen={isModalAskTryOpen !== undefined}
        closable
        onClose={() => setIsModalAskTryOpen(undefined)}
        title="Demander un test"
        width="600px"
      >
        <>
          <p className={style.modalTryName}>Nom de l'application</p>
          {isModalAskTryOpen ? <Utils.AppPresentation application={isModalAskTryOpen} /> : null}
          <Winylo.TextArea
            label="Motif de la demande"
            rows={5}
            value={modalAskTextarea}
            onChange={(e) => setModalAskTextarea(e.target.value)}
            size="md"
          />

          <Winylo.Button
            format="square"
            fullWidth={false}
            onClick={handleAskTryApplication}
            disabled={modalAskTextarea === ''}
            loading={isLoadingAddTrialRequest}
            size="md"
          >
            Demander
          </Winylo.Button>
        </>
      </Utils.Modal>

      {isOpenMoreInfoModal !== undefined ? (
        <MoreInfoModal
          onClose={() => setIsOpenMoreInfoModal(undefined)}
          app={isOpenMoreInfoModal}
          addToBasket={
            !isOpenMoreInfoModal.comingSoon &&
            (companyTrialApplications.includes(isOpenMoreInfoModal.id) || !companyApplications.includes(isOpenMoreInfoModal.id)) ? (
              basket.map((basketItem) => basketItem.application.id).includes(isOpenMoreInfoModal.id) ? (
                <Winylo.Button format="square" color="yellow" onClick={() => removeAppFromBasket(isOpenMoreInfoModal)} size="md">
                  Retirer du panier
                </Winylo.Button>
              ) : (
                <>
                  <Winylo.Button
                    className={classNames(style.moreInfoButtons, style.addToCartThick, 'onboarding-discovercategories-3rd')}
                    format="square"
                    color="yellow"
                    onClick={() => {
                      if (user === undefined) {
                        navigate('/login');
                      } else {
                        addAppToBasket(isOpenMoreInfoModal);
                      }
                    }}
                    style={{
                      whiteSpace: 'nowrap',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}
                    size="md"
                  >
                    Ajouter au panier
                  </Winylo.Button>

                  <div className={style.addToCartThinContainer}>
                    <FontAwesomeIcon className={style.addToCartThin} icon={faCartPlus} onClick={() => navigate('/login')} />
                  </div>
                </>
              )
            ) : null
          }
          tryButton={
            companyApplications.includes(isOpenMoreInfoModal.id) ? (
              <Winylo.Button
                className={style.moreInfoButtons}
                format="square"
                onClick={() => window.open(isOpenMoreInfoModal.urlWeb)}
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '14px',
                  fontWeight: 700,
                }}
                size="md"
              >
                Ouvrir
              </Winylo.Button>
            ) : isOpenMoreInfoModal.trialDuration !== null && !isOpenMoreInfoModal.comingSoon ? (
              TestButton(isOpenMoreInfoModal, true)
            ) : null
          }
          setAppOpened={(denomination) => setIsOpenMoreInfoModal(applications?.find((app) => app.denomination === denomination))}
        />
      ) : null}
    </Utils.Container>
  );
}
