import style from '../MoreInfoModal.module.css';

export default function app(openOtherApp: (denomination: string) => void) {
  return {
    name: 'Planning',
    fileUri: 'https://inari.c-koya.tech/img/apps/big_planning.jpg?v1.0.0',
    description: (
      <>
        <p className={style.title}>Créez, modifiez et diffusez vos plannings en 2 clics</p>
        <p>
          Délaissez le papier et son inertie pour Planning, la win’s qui vous permettra d’optimiser la création, la modification et l’affichage de vos
          plannings.
        </p>

        <div className={style.importantContainer}>
          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/reactivity.svg?v1.0.0" alt="Reactivity" />
              <p>Réactivité</p>
            </div>
            <p>Modifiez, corrigez, ajustez très rapidement vos plannings partout et n’importe quand</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/suivi.svg?v1.0.0" alt="Suivi" />
              <p>Suivi</p>
            </div>
            <p>Suivez la réalisation des tâches à n’importe quel endroit et quand vous le souhaitez</p>
          </div>

          <div className={style.important}>
            <div className={style.importantHeader}>
              <img src="https://inari.c-koya.tech/img/apps/strength/gain_time.svg?v1.0.0" alt="Gain Time" />
              <p>Gain de temps</p>
            </div>
            <p>Terminé les allers-retours, les impressions. Créez et diffusez en quelques clics et à distance.</p>
          </div>
        </div>

        <p className={style.bold}>
          La mise à jour et l’affichage de plannings de production peut parfois être une tâche fastidieuse, surtout lorsque qu’il faut couvrir de la
          distance entre les bureaux et l’atelier.
        </p>

        <p>Quelques clics pour la création, un coup d’œil pour la visualisation !</p>

        <p className={style.title}>Seulement en 2 étapes</p>
        <div className={style.stepsContainer}>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>1</p>
            </p>
            <p>Importez ou créez les actions à réaliser</p>
          </div>
          <div className={style.step}>
            <p className={style.stepNumber}>
              <p>2</p>
            </p>
            <p>Diffusez le planning</p>
          </div>
        </div>
      </>
    ),
    associate: undefined,
    compatibility: (
      <>
        <p className={style.title}>Application compatible avec tous les appareils</p>

        <div className={style.compatibilityContainer}>
          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Ordinateur</p>
              <p>Gestion et consultation sur ordinateur</p>
            </div>
            <div className={style.compatBottom}>
              <img src={process.env.PUBLIC_URL + '/img/ordinateur.png'} alt="Ordinateur" />
            </div>
          </div>

          <div className={style.compatibilityDiv}>
            <div className={style.compatHeader}>
              <p className={style.compatTitle}>Smartphone et Tablette</p>
              <p>Consultation sur smartphone et tablette</p>
            </div>
            <div className={style.compatBottomMultiple}>
              <div className={style.compatBottomRow}>
                <img src={process.env.PUBLIC_URL + '/img/mobile.png'} alt="Smartphone" />
                <img src={process.env.PUBLIC_URL + '/img/tablette.png'} alt="Tablette" />
              </div>
            </div>
          </div>
        </div>
      </>
    ),
  };
}
