import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/api';

import { CompanyProduct, CompanyProductApplication, MidCompany, TestingCompany } from '../../../utils/api/_type';
import { useEffect, useState } from 'react';
import { CheckLg, ChevronDown, ChevronUp, Pencil, PeopleFill, XLg } from 'react-bootstrap-icons';
import Utils from '../../../components/Utils';
import style from './Testing.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToolbox } from '@fortawesome/free-solid-svg-icons';
import Radio from '../../../components/Utils/Radio/Radio';
import { StatsResponse } from '../../../utils/api/stats';
import Divider from '../../../components/Utils/Divider/Divider';
import { dateToString } from '../../../utils/utils';

function Row({
  company,
  openModal,
  renderStatus,
  openUserModal,
}: {
  company: TestingCompany;
  openModal: any;
  renderStatus: any;
  openUserModal: any;
}) {
  const [isProductsOpen, setIsProductsOpen] = useState<boolean>(false);
  const [testingCount, setTestingCount] = useState<number>(0);
  useEffect(() => {
    setTestingCount(company.companyProducts.filter((cp) => cp.status === 'trial').length);
  }, [company]);

  return (
    <>
      <tr key={company.id} className={style.companyRow} onClick={() => setIsProductsOpen((b) => !b)}>
        <td rowSpan={isProductsOpen ? company.companyProducts.length + 1 : 1}>
          {isProductsOpen ? <ChevronUp /> : <ChevronDown />}{' '}
          <PeopleFill
            onClick={(e) => {
              e.stopPropagation();
              openUserModal(company);
            }}
          />{' '}
          {company.name}
        </td>
        <td>{company.siret}</td>
        <td>{company.phone}</td>
        <td style={testingCount > 0 ? { backgroundColor: '#90EE90' } : {}}>{testingCount > 0 ? `Oui (${testingCount})` : 'Non'}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      {isProductsOpen &&
        company.companyProducts.map((cp) => (
          <>
            <tr className={style.productRow}>
              <td></td>
              <td></td>
              <td></td>
              <td>{cp.product.name}</td>
              <td>{cp.trialStartedAt !== null ? dateToString(cp.trialStartedAt) : ''}</td>
              <td>
                {cp.trialEndedAt !== null ? (
                  <>
                    {dateToString(cp.trialEndedAt)}
                    <Pencil onClick={() => openModal(cp as CompanyProductApplication, company)} />
                  </>
                ) : (
                  ''
                )}
              </td>
              <td>{renderStatus(company, cp)}</td>
            </tr>
          </>
        ))}
    </>
  );
}

export default function Testing() {
  const queryClient = useQueryClient();
  const [testing, setTesting] = useState<TestingCompany[]>([]);
  // const { data: testing, isLoading } = useQuery('testing', api.companies.testing);

  const { mutate: getTesting, isLoading: isLoading } = useMutation(api.companies.testing, {
    onSuccess: (data, variable) => {
      data && setTesting(data);
    },
  });

  // Gestion de la modification de la date de fin
  const [isModalUpdateDateOpen, setIsModalUpdateDateOpen] = useState<CompanyProductApplication | undefined>(undefined);
  const [currentCompany, setCurrentCompany] = useState(-1);
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');

  const [checkedFilters, setCheckedFilters] = useState<number>(0);

  const [dateStart, setDateStart] = useState<string>('');
  const [dateEnd, setDateEnd] = useState<string>('');
  const [statsInPeriod, setStatsInPeriod] = useState<StatsResponse | undefined>(undefined);

  const [modalDateSelector, setModalDateSelector] = useState<boolean>(false);
  const [modalUsersCompanyOpen, setModalUsersCompanyOpen] = useState<TestingCompany | undefined>(undefined);

  function openModal(cA: CompanyProductApplication, c: MidCompany) {
    if (cA.trialEndedAt === null) return;

    setIsModalUpdateDateOpen(cA);
    setCurrentCompany(c.id);

    let endDate = new Date(cA.trialEndedAt);

    setCurrentDate(`${endDate.getFullYear()}-${zeroBefore(endDate.getMonth() + 1)}-${zeroBefore(endDate.getDate())}`);
    setCurrentTime(`${zeroBefore(endDate.getHours())}:${zeroBefore(endDate.getMinutes())}`);
  }

  function openUserModal(company: TestingCompany | undefined) {
    setModalUsersCompanyOpen(company);
  }

  function zeroBefore(number: number) {
    return number < 10 ? '0' + number : '' + number;
  }

  const { data: stats, isLoading: isLoadingGlobalStats } = useQuery('stats', () => api.stats.getStats({}));

  const [tx, setTx] = useState<number>(0);
  const { mutate: getStatsInPeriod, isLoading: isLoadingPeriod } = useMutation(api.stats.getStats, {
    onSuccess: (data, variable) => {
      data && setStatsInPeriod(data);
      setTx(data.txSubscribedAfterTest);
    },
  });

  const { mutate: updateCompanyApplication } = useMutation(api.companyApplications.updateCompanyApplication, {
    onSuccess: (data, variable) => {
      queryClient.setQueryData<MidCompany[] | undefined>('testing', (before) =>
        before === undefined
          ? undefined
          : before.map((c) =>
              c.id !== variable.idCompany
                ? c
                : {
                    ...c,
                    companyApplications: c.companyProducts.map((cA) => (cA.id !== variable.id ? cA : { ...data, product: cA.product })),
                  }
            )
      );
      setIsModalUpdateDateOpen(undefined);
    },
  });

  function handleUpdateEndDate() {
    if (isModalUpdateDateOpen === undefined) return;

    let date = new Date(currentDate);

    updateCompanyApplication({
      id: isModalUpdateDateOpen.id,
      idCompany: currentCompany,
      body: {
        trialEndedAt: `${zeroBefore(date.getDate())}/${zeroBefore(date.getMonth() + 1)}/${date.getFullYear()} ${currentTime}`,
      },
    });
  }

  function handleTrialRequest(idCompany: number, id: number, accept: boolean) {
    updateCompanyApplication({
      id: id,
      idCompany: idCompany,
      body: {
        status: accept ? 'trial' : 'trial_refused',
      },
    });
  }

  function renderStatus(company: MidCompany, companyApplication: CompanyProduct) {
    if (companyApplication.status === 'trial') {
      return <span style={{ backgroundColor: '#89ce75', padding: '4px' }}>En cours</span>;
    }

    if (companyApplication.status === 'trial_request') {
      return (
        <>
          Test Demandé <CheckLg onClick={() => handleTrialRequest(company.id, companyApplication.id, true)} />
          <XLg onClick={() => handleTrialRequest(company.id, companyApplication.id, false)} />
        </>
      );
    }

    if (companyApplication.status === 'trial_refused') {
      return <span style={{ backgroundColor: 'orange', padding: '4px' }}>Test refusé</span>;
    }

    return <span style={{ backgroundColor: 'orange', padding: '4px' }}>Test terminé</span>;
  }

  useEffect(() => {
    // getTesting({});
    // getStatsInPeriod({});
    getStatsLastYear();
  }, []);

  function getStatsLastYear() {
    const startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    getTesting({ dateStart: startDate, dateEnd: new Date() });
    getStatsInPeriod({ dateStart: startDate, dateEnd: new Date() });
  }

  return (
    <Utils.Card width="100%" style={{ marginTop: 0 }}>
      <Container>
        <h2>Suivi des tests</h2>
      </Container>
      {/* {isLoading || isLoadingGlobalStats || isLoadingPeriod || testing === undefined ? (
        <Spinner animation="border" />
      ) : ( */}
      <>
        <h2>Total</h2>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Table title="Statistiques globales" responsive style={{ border: '1px solid', textAlign: 'center' }}>
            <thead style={{ border: '1px solid', fontWeight: 600 }}>
              <td style={{ border: '1px solid' }}>Utilisateurs inscrits</td>
              <td style={{ border: '1px solid' }}>Entreprises créées</td>
              <td style={{ border: '1px solid' }}>Tests en cours</td>
              <td style={{ border: '1px solid' }}>Tests terminés</td>
              <td style={{ border: '1px solid' }}>Abonnements en cours</td>
              <td style={{ border: '1px solid' }}>Abonnements terminés</td>
            </thead>
            <tbody style={{ border: '1px solid' }}>
              <tr style={{ border: '1px solid' }}>
                <td style={{ border: '1px solid' }}>
                  <span>{(stats && stats.nbUsers) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{(stats && stats.nbCompanies) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{(stats && stats.nbInTest) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{(stats && stats.nbTestExpired) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{(stats && stats.nbSubscribed) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{(stats && stats.nbUnsubscribed) || 0}</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <Divider variant="gray" />

        <h2>Sur une période</h2>

        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          {/* <Radio
              label="Depuis le début"
              checked={checkedFilters[0]}
              onClick={() => setCheckedFilters((o) => [!o[0], ...o.slice(1, o.length)])}
              onChange
              radioGroup="stats-filters"
            /> */}
          <Radio
            label="Dernière année"
            checked={checkedFilters === 0}
            onClick={() => setCheckedFilters(0)}
            onChange={(e) => {
              if (e.target.value !== 'on') return;
              getStatsLastYear();
            }}
            radioGroup="stats-filters"
          />
          <Radio
            label="Dernier trimestre"
            checked={checkedFilters === 1}
            onClick={() => setCheckedFilters(1)}
            onChange={(e) => {
              if (e.target.value !== 'on') return;
              const startDate = new Date();
              startDate.setMonth(startDate.getMonth() - 3);
              getTesting({ dateStart: startDate, dateEnd: new Date() });
              getStatsInPeriod({ dateStart: startDate, dateEnd: new Date() });
            }}
            radioGroup="stats-filters"
          />
          <Radio
            label="Dernier mois"
            checked={checkedFilters === 2}
            onClick={() => setCheckedFilters(2)}
            onChange={(e) => {
              if (e.target.value !== 'on') return;
              const startDate = new Date();
              startDate.setMonth(startDate.getMonth() - 1);
              getTesting({ dateStart: startDate, dateEnd: new Date() });
              getStatsInPeriod({ dateStart: startDate, dateEnd: new Date() });
            }}
            radioGroup="stats-filters"
          />
          <Radio
            label="Dernière semaine"
            checked={checkedFilters === 3}
            onClick={() => setCheckedFilters(3)}
            onChange={(e) => {
              if (e.target.value !== 'on') return;
              const startDate = new Date();
              startDate.setDate(startDate.getDate() - 7);
              getTesting({ dateStart: startDate, dateEnd: new Date() });
              getStatsInPeriod({ dateStart: startDate, dateEnd: new Date() });
            }}
            radioGroup="stats-filters"
          />
          <Radio
            label="Dernier jour"
            checked={checkedFilters === 4}
            onClick={() => setCheckedFilters(4)}
            onChange={(e) => {
              if (e.target.value !== 'on') return;
              const startDate = new Date();
              startDate.setDate(startDate.getDate() - 1);
              getTesting({ dateStart: startDate, dateEnd: new Date() });
              getStatsInPeriod({ dateStart: startDate, dateEnd: new Date() });
            }}
            radioGroup="stats-filters"
          />
          {/* <Radio
              label="Dernière heure"
              checked={checkedFilters[6]}
              onClick={() => setCheckedFilters((o) => [...o.slice(0, 6), !o[6], ...o.slice(7, o.length)])}
              radioGroup="stats-filters"
            /> */}
          <Radio
            label="Personnalisé"
            checked={checkedFilters === 5}
            onClick={() => setCheckedFilters(5)}
            onChange={(e) => {
              if (e.target.value !== 'on') return;
              getTesting({ dateStart: new Date(dateStart), dateEnd: new Date(dateEnd) });
              getStatsInPeriod({ dateStart: new Date(dateStart), dateEnd: new Date(dateEnd) });
            }}
            radioGroup="stats-filters"
          />

          {checkedFilters === 5 && (
            <Button>
              <FontAwesomeIcon icon={faToolbox} onClick={() => setModalDateSelector(true)} />
            </Button>
          )}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Table title="Statistiques globales" responsive style={{ border: '1px solid', textAlign: 'center' }}>
            <thead style={{ border: '1px solid', fontWeight: 600 }}>
              <td style={{ border: '1px solid' }}>Nouveaux utilisateurs</td>
              <td style={{ border: '1px solid' }}>Nouvelles entreprises</td>
              <td style={{ border: '1px solid' }}>Tests lancés</td>
              <td style={{ border: '1px solid' }}>Tests terminés</td>
              <td style={{ border: '1px solid' }}>Abonnements lancés</td>
              <td style={{ border: '1px solid' }}>Abonnements terminés</td>
              <td style={{ border: '1px solid' }}>Taux d'attrition</td>
              <td style={{ border: '1px solid' }}>
                Taux d'abonnements
                <br />
                après un test
              </td>
            </thead>
            <tbody style={{ border: '1px solid' }}>
              <tr style={{ border: '1px solid' }}>
                <td style={{ border: '1px solid' }}>
                  <span>{(statsInPeriod && statsInPeriod.nbUsers) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{(statsInPeriod && statsInPeriod.nbCompanies) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{(statsInPeriod && statsInPeriod.nbInTest) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{(statsInPeriod && statsInPeriod.nbTestExpired) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{(statsInPeriod && statsInPeriod.nbSubscribed) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{(statsInPeriod && statsInPeriod.nbUnsubscribed) || 0}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{`${(statsInPeriod && (statsInPeriod.nbUnsubscribed / statsInPeriod.nbSubscribed) * 100) || 0}%`}</span>
                </td>
                <td style={{ border: '1px solid' }}>
                  <span>{`${tx * 100 || 0}%`}</span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        {isLoading || isLoadingGlobalStats || isLoadingPeriod || testing === undefined ? (
          <Spinner animation="border" />
        ) : (
          <Table responsive>
            <thead>
              <tr>
                <th>Nom de l'entreprise</th>
                <th>SIREN</th>
                <th>Numéro de téléphone</th>
                <th>Test en cours</th>
                <th>Application</th>
                <th>Date de début</th>
                <th>Date de fin</th>
                <th>Statut</th>
              </tr>
            </thead>
            <tbody>
              {testing.map((company) => (
                <Row company={company} openModal={openModal} renderStatus={renderStatus} openUserModal={openUserModal} />
              ))}
              {/* {testing.map((t) =>
              t.companyProducts
                .filter((cP) => cP.type === 'application')
                .map((cA) => (
                  <tr key={cA.id}>
                    <td>{t.name}</td>
                    <td>{t.siret}</td>
                    <td>{t.phone}</td>
                    <td>{cA.product.name}</td>
                    <td>{cA.trialStartedAt !== null ? dateToString(cA.trialStartedAt) : ''}</td>
                    <td>
                      {cA.trialEndedAt !== null ? (
                        <>
                          {dateToString(cA.trialEndedAt)}
                          <Pencil onClick={() => openModal(cA as CompanyProductApplication, t)} />
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{renderStatus(t, cA)}</td>
                  </tr>
                ))
            )} */}
            </tbody>
          </Table>
        )}
      </>
      {/* )} */}

      <Modal show={isModalUpdateDateOpen !== undefined} onHide={() => setIsModalUpdateDateOpen(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier la date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Date de fin de l'essai</Form.Label>
            <Form.Control type="date" value={currentDate} onChange={(e) => setCurrentDate(e.target.value)} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Heure de la fin de l'essai</Form.Label>
            <Form.Control type="time" value={currentTime} onChange={(e) => setCurrentTime(e.target.value)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalUpdateDateOpen(undefined)}>
            Fermer
          </Button>
          <Button onClick={handleUpdateEndDate} disabled={currentDate === ''}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalUsersCompanyOpen !== undefined} onHide={() => setModalUsersCompanyOpen(undefined)}>
        <Modal.Header closeButton>
          <Modal.Title>Utilisateurs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              {modalUsersCompanyOpen &&
                modalUsersCompanyOpen.users?.map((u) => (
                  <tr>
                    <td>{u.firstname}</td>
                    <td>{u.lastname}</td>
                    <td>{u.email}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalUsersCompanyOpen(undefined)}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalDateSelector}
        onHide={() => {
          getStatsInPeriod({ dateStart: new Date(dateStart), dateEnd: new Date(dateEnd) });
          getTesting({ dateStart: new Date(dateStart), dateEnd: new Date(dateEnd) });
          setModalDateSelector(false);
        }}
      >
        <Modal.Body>
          <form>
            <div>
              Date début : <Utils.Input type="date" value={dateStart} onChange={(e) => setDateStart(e.target.value)} />
            </div>
            <div />
            Date fin : <Utils.Input type="date" value={dateEnd} onChange={(e) => setDateEnd(e.target.value)} />
          </form>
        </Modal.Body>
      </Modal>
    </Utils.Card>
  );
}
