import { faCheck, faCog, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useMutation } from 'react-query';
import Utils from '../../components/Utils';
import api from '../../utils/api';
import { CompanyUser, ROLE } from '../../utils/api/_type';
import Winylo from '@winylo/winylo-react-component';

import sharedStyle from './css/Shared.module.css';
import style from './css/UserItem.module.css';

interface UserItemProps {
  user: CompanyUser;
  handleOpenSetting: (user: CompanyUser) => void;
  handleConfirmRegistration: (id: number) => void;
  handleDenyRegistration: (id: number) => void;
  handleDeleteUser: (id: number) => void;
  setUsers: React.Dispatch<React.SetStateAction<CompanyUser[]>>;
}

export default function UserItem(props: UserItemProps) {
  const { mutate: updateUser } = useMutation(api.companies.updateCompanyUser, {
    onSuccess: (res, variable) => {
      props.setUsers((prev) => {
        return prev.map((user) => {
          if (user.id === variable.id) {
            return {
              ...user,
              canConnect: !!variable.body.canConnect,
            };
          }
          return user;
        });
      });
    },
  });

  const { mutate: toggleCompanyAdmin } = useMutation(api.users.toggleCompanyAdmin, {
    onSuccess: (res, variable) => {
      props.setUsers((prev) => {
        return prev.map((user) => {
          if (user.id === variable) {
            return {
              ...user,
              roles: user.roles.includes(ROLE.ROLE_COMPANY_ADMIN)
                ? user.roles.filter((role) => role !== ROLE.ROLE_COMPANY_ADMIN)
                : [...user.roles, ROLE.ROLE_COMPANY_ADMIN],
            };
          }
          return user;
        });
      });
    },
  });
  const { mutate: toggleCompanyAccountant } = useMutation(api.users.toggleCompanyAccountant, {
    onSuccess: (res, variable) => {
      props.setUsers((prev) => {
        return prev.map((user) => {
          if (user.id === variable) {
            return {
              ...user,
              roles: user.roles.includes(ROLE.ROLE_COMPANY_ACCOUNTANT)
                ? user.roles.filter((role) => role !== ROLE.ROLE_COMPANY_ACCOUNTANT)
                : [...user.roles, ROLE.ROLE_COMPANY_ACCOUNTANT],
            };
          }
          return user;
        });
      });
    },
  });

  /**
   * Définie si l'utilisateur peut se connecter selon la valeur du switch
   *
   * @param e Input event du switch
   * @param id id de l'utilisateur à modifier
   */
  function handleCanConnect(e: any, id: number) {
    updateUser({
      id,
      body: {
        canConnect: e.target.checked,
      },
    });
  }

  /**
   * Inverse le rôle ROLE_COMPANY_ADMIN de l'utilisateur
   *
   * @param id id de l'utilisateur à modifier
   */
  function handleCompanyAdmin(id: number) {
    toggleCompanyAdmin(id);
  }

  /**
   * Inverse le rôle ROLE_COMPANY_ACCOUNTANT de l'utilisateur
   *
   * @param id id de l'utilisateur à modifier
   */
  function handleCompanyAccountant(id: number) {
    toggleCompanyAccountant(id);
  }

  return (
    <div className={classNames(style.gridRow, sharedStyle.gridRow)}>
      <span className={style.gridColumn}>{props.user.firstname}</span>
      <span className={style.gridColumn}>{props.user.lastname}</span>
      <span className={style.gridColumn}>{props.user.username}</span>
      <span className={style.gridColumn}>{props.user.email}</span>
      <div className={classNames(style.switchColumn, 'onboarding-edituser-3rd')}>
        {!props.user.waitingValidation && (
          <>
            <div title="Permet de bloquer la connexion d'un compte" className={style.switchItem}>
              <Winylo.Switch label="Actif" checked={props.user.canConnect} onClick={(e) => handleCanConnect(e, props.user.id)} />
            </div>
            <div className={style.switchDivider} />
            <div
              title='Donne accès à la page "Mes abonnements"'
              className={classNames(style.switchItem, props.user.roles.includes(ROLE.ROLE_COMPANY_ADMIN) && style.switchDisabled)}
              style={{ marginBottom: '0.5rem' }}
            >
              <Winylo.Switch
                label="Comptable"
                checked={props.user.roles.includes(ROLE.ROLE_COMPANY_ADMIN) || props.user.roles.includes(ROLE.ROLE_COMPANY_ACCOUNTANT)}
                onClick={(e) => handleCompanyAccountant(props.user.id)}
              />
              {props.user.roles.includes(ROLE.ROLE_COMPANY_ADMIN)}
            </div>
            <div title="Donne accès à la gestion de l'entreprise, un admin " className={style.switchItem}>
              <Winylo.Switch
                label="Admin"
                checked={props.user.roles.includes(ROLE.ROLE_COMPANY_ADMIN)}
                onClick={(e) => handleCompanyAdmin(props.user.id)}
              />
            </div>
          </>
        )}
      </div>
      <div className={style.buttonsColumn}>
        {!props.user.waitingValidation ? (
          <>
            <div
              id="onboarding-edituser-next-4th"
              className={classNames(style.button, style.settingButton, 'onboarding-edituser-4th')}
              onClick={() => props.handleOpenSetting(props.user)}
            >
              <FontAwesomeIcon className={style.buttonIcon} icon={faCog} />
            </div>
            <div className={classNames(style.button, style.deleteButton)} onClick={() => props.handleDeleteUser(props.user.id)}>
              <FontAwesomeIcon className={style.buttonIcon} icon={faTrash} />
            </div>
          </>
        ) : (
          <>
            <div className={classNames(style.button, style.validateButton)} onClick={() => props.handleConfirmRegistration(props.user.id)}>
              <FontAwesomeIcon className={style.buttonIcon} icon={faCheck} />
            </div>
            <div className={classNames(style.button, style.deleteButton)} onClick={() => props.handleDenyRegistration(props.user.id)}>
              <FontAwesomeIcon className={style.buttonIcon} icon={faTimes} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
