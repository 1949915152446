import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Utils from '../Utils';

import style from './MoreInfoModal.module.css';

import capture from './Modal/Capture';
import kaizen from './Modal/Kaizen';
import catalogue from './Modal/Catalogue';
import planning from './Modal/Planning';
import inventaire from './Modal/Inventaire';
import vizu from './Modal/Vizu';

import { MidApplication } from '../../utils/api/_type';

const appAssociation: any = {
  capture: capture,
  kaizen: kaizen,
  catalogue: catalogue,
  planning: planning,
  ckomptage: inventaire,
  vizu: vizu,
};

interface Props {
  onClose: () => void;
  app: MidApplication;
  tryButton: any;
  addToBasket: any;
  setAppOpened: (denomination: string) => void;
}

export default function MoreInfoModal(props: Props) {
  const [app, setApp] = useState<any>(undefined);

  useEffect(() => {
    if (!Object.keys(appAssociation).includes(props.app.denomination)) {
      props.onClose();
    } else {
      setApp(appAssociation[props.app.denomination](props.setAppOpened));
    }

    setSelectedTab('description');
  }, [props]);

  const [selectedTab, setSelectedTab] = useState<'description' | 'associate' | 'compatibility' | 'video'>('description');

  if (app === undefined) {
    return <></>;
  }

  return (
    <Utils.Modal width="80%" verticalCenter={false} closable onClose={props.onClose}>
      <div className={style.twoColumns}>
        <div className={style.leftColumn}>
          <img src={app.fileUri} alt={`Logo de ${app.name}`} className={style.iconLogo} />
          <p className={style.titleModal}>{app.name}</p>
          <div className={style.divider}></div>

          <div className={style.menuContainer}>
            <p
              className={classNames(style.menuItem, {
                [style.selected]: selectedTab === 'description',
              })}
              onClick={() => setSelectedTab('description')}
            >
              Description
            </p>

            {app.associate === undefined ? null : (
              <p
                className={classNames(style.menuItem, {
                  [style.selected]: selectedTab === 'associate',
                })}
                onClick={() => setSelectedTab('associate')}
              >
                Les Win's associées
              </p>
            )}

            <p
              className={classNames(style.menuItem, {
                [style.selected]: selectedTab === 'compatibility',
              })}
              onClick={() => setSelectedTab('compatibility')}
            >
              Compatibilité
            </p>

            {app.video !== undefined && (
              <p
                className={classNames(style.menuItem, {
                  [style.selected]: selectedTab === 'video',
                })}
                onClick={() => setSelectedTab('video')}
              >
                Vidéo
              </p>
            )}
          </div>

          <div className={style.bottomButtons}>
            {props.addToBasket}
            {props.tryButton}
          </div>
        </div>

        <div className={style.rightColumn}>
          {selectedTab === 'description'
            ? app.description
            : selectedTab === 'associate'
            ? app.associate
            : selectedTab === 'video'
            ? app.video
            : app.compatibility}
        </div>
      </div>
    </Utils.Modal>
  );
}
